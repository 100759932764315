import { AuthUser } from '../models/auth-user.model';

export const AUTH_STORE = 'AUTH_STORE';

export interface AuthState {
    user: AuthUser;
    error: any;
    warning: {
        titulo: string;
        mensagem: string;
    };
    success: any;
    isProcessing: boolean;
}

export const authInitialState: AuthState = {
    user: undefined,
    error: undefined,
    warning: undefined,
    success: undefined,
    isProcessing: false,
};
