import { NgModule } from '@angular/core';

import { CadastrosAuthModule } from './cadastros/cadastros-auth.module';
import { CrmAuthModule } from './crm/crm-auth.module';
import { AdministradorGuard } from './guards/administrador.guard';
import { AutenticadoGuard } from './guards/autenticado.guard';
import { CadastrosGuard } from './guards/cadastros.guard';
import { CodigoColaboradorGuard } from './guards/codigo-colaborador.guard';
import { ComprasGuard } from './guards/compras.guard';
import { CrmClientesGuard } from './guards/crm-clientes.guard';
import { EstoqueGuard } from './guards/estoque.guard';
import { ExpiracaoGuard } from './guards/expiracao.guard';
import { ExportacaoGuard } from './guards/exportacao.guard';
import { FinanceiroGuard } from './guards/financeiro.guard';
import { FiscalGuard } from './guards/fiscal.guard';
import { LoginInternoGuard } from './guards/login-interno.guard';
import { VendasGuard } from './guards/vendas.guard';
import { AuthService } from './services/auth.service';
import { SistemaAuthModule } from './sistema/sistema-auth.module';
import { AuthStoreModule } from './store/auth.store.module';

@NgModule({
    imports: [
        AuthStoreModule,

        CadastrosAuthModule,
        CrmAuthModule,
        SistemaAuthModule,
    ],
    providers: [
        AuthService,

        AutenticadoGuard,
        CodigoColaboradorGuard,
        ExpiracaoGuard,
        LoginInternoGuard,
        AdministradorGuard,

        CadastrosGuard,
        CrmClientesGuard,
        ComprasGuard,
        EstoqueGuard,
        VendasGuard,
        FinanceiroGuard,
        FiscalGuard,
        ExportacaoGuard,
    ],
})
export class AuthModule {}
