import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'otk-cmp-remove-button',
    templateUrl: './cmp-remove-button.component.html',
    styleUrls: ['./cmp-remove-button.component.scss'],
})
export class CmpRemoveButtonComponent implements OnInit {
    @Input() enabled: boolean = true;

    constructor() {}

    ngOnInit() {}
}
