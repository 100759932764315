import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DxPopoverModule, DxTemplateModule } from 'devextreme-angular';

import { CmpLabelboxComponent } from './cmp-labelbox.component';

@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        DxPopoverModule,
        DxTemplateModule,
    ],
    declarations: [
        CmpLabelboxComponent
    ],
    exports: [
        CmpLabelboxComponent,
    ]
})
export class LabelboxsComponentsModule { }
