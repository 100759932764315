import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/Rx';

import { AppSettings } from './app.settings';

@Injectable()
export class AppTokenInterceptor implements HttpInterceptor {
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (req.url.endsWith('/security/currentuser')) {
            return next.handle(req);
        }

        if (req.url.startsWith('https://viacep.com')) {
            return next.handle(req);
        }

        req = req.clone({
            headers: AppSettings.HTTP_AUTHORIZATION_HEADERS(req.headers),
        });

        return next.handle(req).pipe(
            catchError((err) => {
                if (err instanceof HttpErrorResponse && err.status == 401) {
                    AppSettings.unauthorized$.next();
                }

                if (
                    err instanceof HttpErrorResponse &&
                    err.error instanceof Blob &&
                    err.error.type === 'application/json'
                ) {
                    // https://github.com/angular/angular/issues/19888
                    // When request of type Blob, the error is also in Blob instead of object of the json data
                    return new Promise<any>((resolve, reject) => {
                        let reader = new FileReader();
                        reader.onload = (e: Event) => {
                            try {
                                const errmsg = JSON.parse(
                                    (<any>e.target).result
                                );
                                reject(
                                    new HttpErrorResponse({
                                        error: errmsg,
                                        headers: err.headers,
                                        status: err.status,
                                        statusText: err.statusText,
                                        url: err.url,
                                    })
                                );
                            } catch (e) {
                                console.log(e);
                                reject(err);
                            }
                        };
                        reader.onerror = (e) => {
                            console.log(e);
                            reject(err);
                        };
                        reader.readAsText(err.error);
                    });
                }
                return throwError(err);
            })
        );
    }
}
