import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { AppHelper } from '../../../../app.helper';

@Component({
    selector: 'otk-cmp-numberbox-disabled-label',
    templateUrl: './cmp-numberbox-disabled-label.component.html',
    styleUrls: ['./cmp-numberbox-disabled-label.component.scss'],
})
export class CmpNumberboxDisabledLabelComponent implements OnInit {
    control: FormControl = new FormControl('{value: 0, disabled: true}', []);

    @Input() label: string = null;
    @Input() format: string = '#,##0.00';
    @Input() value: number = 0;
    @Input() help: string = null;
    @Input() placeholder: string = '';

    @Input() width: string = '100%';
    @Input() widthHelp: string = '300px';
    @Input() showCurrency: boolean = false;

    popoverVisible: boolean;

    constructor() {}

    ngOnInit() {}

    ngOnDestroy() {}

    ngOnChanges() {
        AppHelper.setCtrlValue(this.control, this.value);
        AppHelper.setCtrlEnable(this.control, false);
    }

    get _caption(): string {
        return this.label.replace(/\*/g, ' ');
    }

    get _captionLabel(): string {
        // if (this.control && hasRequiredField(this.control)) {
        //     let t = this.label.replace(/\*/g, ' ');
        //     return `${t} *`;
        // }
        return this.label;
    }

    togglePopover() {
        this.popoverVisible = !this.popoverVisible;
    }
}
