import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import {
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
} from '@angular/material';
import { TextboxLabelModule } from '../textbox-label/textbox-label.module';
import { CmpTextboxDisabledLabelButtonComponent } from './cmp-textbox-disabled-label-button.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,

        TextboxLabelModule,
    ],
    declarations: [CmpTextboxDisabledLabelButtonComponent],
    exports: [CmpTextboxDisabledLabelButtonComponent],
})
export class TextboxDisabledLabelButtonModule {}
