import { NgRxAction } from '../../store/ngrx-action';

export enum AuthActions {
    AUTENTICAR_LOGIN = '[Auth] Autenticar Login',
    AUTENTICAR_LOGIN_INTERNO = '[Auth] Autenticar Login Interno',
    AUTENTICAR_LOGIN_INTERNO_ADMIN = '[Auth] Autenticar Login Interno ADMIN',
    AUTENTICAR_LOGIN_CLIENTE = '[Auth] Autenticar Login Cliente',
    AUTENTICAR_LOGIN_BASE_OTK = '[Auth] Autenticar Login Base OTK',
    AUTENTICAR_LOGIN_BASE_AFIXGRAF = '[Auth] Autenticar Login Base AFIXGRAF',
    AUTENTICAR_LOGIN_BRADESCO = '[Auth] Autenticar Login Bradesco',
    LOGIN_AUTENTICADO = '[Auth] Login Autenticado com sucesso',

    REFRESH_TOKEN = '[Auth] Refresh Token',

    RECUPERAR_LOGIN = '[Auth] Recuperar Login',
    LOGIN_RECUPERADO_COM_SUCESSO = '[Auth] Login recuperado com sucesso',

    EFETUAR_LOGOUT = '[Auth] Efeturar Logout',

    REGISTRAR_LOGIN = '[Auth] Registrar Login',
    REGISTRAR_LOGIN_COM_SUCESSO = '[Auth] Registrar Login com sucesso',
    REGISTRAR_LOGIN_WCF = '[Auth] Registrar LoginWCF',
    REGISTRAR_LOGIN_WCF_COM_SUCESSO = '[Auth] Registrar LoginWCF com sucesso',
    REGISTRAR_LOGOUT = '[Auth] Registrar Logout',
    REGISTRAR_LOGOUT_COM_SUCESSO = '[Auth] Registrar Logout com sucesso',
    ERRO_EXECUTANDO_OPERACAO = '[Auth] Erro executando operacao',

    ENVIAR_NOVA_SENHA = '[Auth] Enviar nova senha',
    ENVIAR_NOVA_SENHA_COM_SUCESSO = '[Auth] Enviar nova senha com sucesso',

    CRIAR_NOVA_CONTA = '[Auth] Criar nova conta',
    CRIAR_NOVA_CONTA_COM_SUCESSO = '[Auth] Criar nova conta com sucesso',

    CONFIRMAR_EMAIL_SENHA = '[Auth] Confirmar email/senha',
    CONFIRMAR_EMAIL_SENHA_COM_SUCESSO = '[Auth] Confirmar email/senha com sucesso',

    REDEFINIR_SENHA = '[Auth] Redefinir senha',
    REDEFINIR_SENHA_COM_SUCESSO = '[Auth] Redefinir senha com sucesso',

    LIMPAR = '[Auth] Limpar',
    ERRO = '[Auth] Erro',
}

export class AutenticarLoginAction extends NgRxAction<any> {
    type = AuthActions.AUTENTICAR_LOGIN;
}
export class AutenticarLoginInternoAction extends NgRxAction<any> {
    type = AuthActions.AUTENTICAR_LOGIN_INTERNO;
}
export class AutenticarLoginInternoAdminAction extends NgRxAction<any> {
    type = AuthActions.AUTENTICAR_LOGIN_INTERNO_ADMIN;
}
export class AutenticarLoginClienteAction extends NgRxAction<any> {
    type = AuthActions.AUTENTICAR_LOGIN_CLIENTE;
}
export class AutenticarLoginBaseOTKAction extends NgRxAction<any> {
    type = AuthActions.AUTENTICAR_LOGIN_BASE_OTK;
}
export class AutenticarLoginBaseAfixGrafAction extends NgRxAction<any> {
    type = AuthActions.AUTENTICAR_LOGIN_BASE_AFIXGRAF;
}
export class AutenticarLoginBradescoAction extends NgRxAction<any> {
    type = AuthActions.AUTENTICAR_LOGIN_BRADESCO;
}
export class LoginAutenticadoAction extends NgRxAction<any> {
    type = AuthActions.LOGIN_AUTENTICADO;
}

export class RecuperarLoginAction extends NgRxAction<any> {
    type = AuthActions.RECUPERAR_LOGIN;
}
export class LoginRecuperadoComSucessoAction extends NgRxAction<any> {
    type = AuthActions.LOGIN_RECUPERADO_COM_SUCESSO;
}

export class EfetuarLogoutAction extends NgRxAction<any> {
    type = AuthActions.EFETUAR_LOGOUT;
}

export class RefreshTokenAction extends NgRxAction<any> {
    type = AuthActions.REFRESH_TOKEN;
}

export class RegistrarLoginAction extends NgRxAction<any> {
    type = AuthActions.REGISTRAR_LOGIN;
}
export class RegistrarLoginComSucessoAction extends NgRxAction<any> {
    type = AuthActions.REGISTRAR_LOGIN_COM_SUCESSO;
}
export class RegistrarLoginWCFAction extends NgRxAction<any> {
    type = AuthActions.REGISTRAR_LOGIN_WCF;
}
export class RegistrarLoginWCFComSucessoAction extends NgRxAction<any> {
    type = AuthActions.REGISTRAR_LOGIN_WCF_COM_SUCESSO;
}
export class RegistrarLogoutAction extends NgRxAction<any> {
    type = AuthActions.REGISTRAR_LOGOUT;
}
export class RegistrarLogoutComSucessoAction extends NgRxAction<any> {
    type = AuthActions.REGISTRAR_LOGOUT_COM_SUCESSO;
}
export class ErroExecutandoOperacaoAction extends NgRxAction<any> {
    type = AuthActions.ERRO_EXECUTANDO_OPERACAO;
}

export class EnviarNovaSenhaAction extends NgRxAction<any> {
    type = AuthActions.ENVIAR_NOVA_SENHA;
}
export class EnviarNovaSenhaComSucessoAction extends NgRxAction<any> {
    type = AuthActions.ENVIAR_NOVA_SENHA_COM_SUCESSO;
}

export class CriarNovaContaAction extends NgRxAction<any> {
    type = AuthActions.CRIAR_NOVA_CONTA;
}
export class CriarNovaContaComSucessoAction extends NgRxAction<any> {
    type = AuthActions.CRIAR_NOVA_CONTA_COM_SUCESSO;
}

export class ConfirmarEmailSenhaAction extends NgRxAction<any> {
    type = AuthActions.CONFIRMAR_EMAIL_SENHA;
}
export class ConfirmarEmailSenhaComSucessoAction extends NgRxAction<any> {
    type = AuthActions.CONFIRMAR_EMAIL_SENHA_COM_SUCESSO;
}
export class RedefinirSenhaAction extends NgRxAction<any> {
    type = AuthActions.REDEFINIR_SENHA;
}

export class RedefinirSenhaComSucessoAction extends NgRxAction<any> {
    type = AuthActions.REDEFINIR_SENHA_COM_SUCESSO;
}

export class LimparAction extends NgRxAction<any> {
    type = AuthActions.LIMPAR;
}
export class ErroAction extends NgRxAction<any> {
    type = AuthActions.ERRO;
}

export type AuthAction =
    | AutenticarLoginAction
    | AutenticarLoginInternoAction
    | AutenticarLoginInternoAdminAction
    | AutenticarLoginClienteAction
    | AutenticarLoginBaseOTKAction
    | AutenticarLoginBaseAfixGrafAction
    | AutenticarLoginBradescoAction
    | LoginAutenticadoAction
    | RecuperarLoginAction
    | LoginRecuperadoComSucessoAction
    | EfetuarLogoutAction
    | RefreshTokenAction
    | RegistrarLoginAction
    | RegistrarLoginComSucessoAction
    | RegistrarLoginWCFAction
    | RegistrarLoginWCFComSucessoAction
    | RegistrarLogoutAction
    | RegistrarLogoutComSucessoAction
    | ErroExecutandoOperacaoAction
    | EnviarNovaSenhaComSucessoAction
    | EnviarNovaSenhaAction
    | CriarNovaContaAction
    | CriarNovaContaComSucessoAction
    | RedefinirSenhaAction
    | RedefinirSenhaComSucessoAction
    | LimparAction
    | ErroAction;
