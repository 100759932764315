import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

import { StateModeEnum } from '../../../../app.enums';

@Component({
    selector: 'otk-cmp-process-warn-button',
    templateUrl: './cmp-process-warn-button.component.html',
    styleUrls: ['./cmp-process-warn-button.component.scss'],
})
export class CmpProcessWarnButtonComponent implements OnInit {
    enabledValid: boolean = true;
    visibleState: boolean = false;

    private stateSubscription: Subscription;
    private validSubscription: Subscription;

    @Input() visible: boolean = true;
    @Input() enabled: boolean = true;
    @Input() state$: BehaviorSubject<StateModeEnum> =
        new BehaviorSubject<StateModeEnum>(StateModeEnum.undefined);
    @Input() valid$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        true
    );

    @Output() processClick: EventEmitter<any> = new EventEmitter();

    constructor() {}

    ngOnInit() {
        this.stateSubscription = this.state$.subscribe((r) => {
            this.visibleState =
                r == StateModeEnum.browse || r == StateModeEnum.selected;
        });

        this.validSubscription = this.valid$.subscribe((r) => {
            this.enabledValid = r;
        });
    }

    ngOnDestroy() {
        this.stateSubscription.unsubscribe();
        this.validSubscription.unsubscribe();
    }

    actionButtonProcessClick($event): void {
        this.processClick.emit($event);
    }
}
