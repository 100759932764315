import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule, MatTooltipModule } from '@angular/material';
import { DxCheckBoxModule, DxPopoverModule } from 'devextreme-angular';

import { CmpCheckboxFilterComponent } from './checkbox-filter/cmp-checkbox-filter.component';
import { CmpCheckboxInputComponent } from './checkbox-input/cmp-checkbox-input.component';
import { CmpCheckboxInputNovoComponent } from './checkbox-input-novo/cmp-checkbox-input-novo.component';
import { CmpCheckboxLabelComponent } from './checkbox-label/cmp-checkbox-label.component';
import { CmpLoginCheckboxLabelComponent } from './login-checkbox-label/cmp-login-checkbox-label.component';
import { CmpCheckboxMaterialComponent } from './checkbox-material/cmp-checkbox-material.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        MatCheckboxModule,
        MatTooltipModule,
        DxCheckBoxModule,
        DxPopoverModule,
    ],
    exports: [
        CmpCheckboxLabelComponent,
        CmpLoginCheckboxLabelComponent,
        CmpCheckboxMaterialComponent,
        CmpCheckboxFilterComponent,
        CmpCheckboxInputComponent,
        CmpCheckboxInputNovoComponent,
    ],
    declarations: [
        CmpCheckboxLabelComponent,
        CmpLoginCheckboxLabelComponent,
        CmpCheckboxMaterialComponent,
        CmpCheckboxFilterComponent,
        CmpCheckboxInputComponent,
        CmpCheckboxInputNovoComponent,
    ],
})
export class CheckboxesComponentsModule {}
