import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanLoad,
    Route,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import { AppRoutingService } from '../../app-routing.service';
import { AuthService } from '../services/auth.service';
@Injectable()
export class EstoqueGuard implements CanActivate, CanLoad {
    constructor(
        private _routingService: AppRoutingService,
        private _authService: AuthService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        return this._moduloEstoque();
    }

    canLoad(route: Route): Observable<boolean> {
        return this._moduloEstoque();
    }

    private _moduloEstoque(): Observable<boolean> {
        this._authService.recuperarLogin();

        return this._authService.moduloEstoque$.pipe(
            first(),
            map((r) => {
                if (!r) {
                    this._routingService.sistemaAvisoModulo();
                    return false;
                }
                return r;
            }),
            catchError((e) => {
                return of(false);
            })
        );
    }
}
