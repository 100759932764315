import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'otk-mat-input-span',
    templateUrl: './mat-input-span.component.html',
    styleUrls: ['./mat-input-span.component.scss'],
})
export class MatInputSpanComponent implements OnInit {
    @Input() caption: string = '';
    @Input() value: string = '';
    @Input() enabled: boolean = true;

    constructor() {}

    ngOnInit() {}
}
