import { Injectable, ViewContainerRef } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import notify from 'devextreme/ui/notify';
import { Observable } from 'rxjs';

import { AppService } from '../../app.service';

@Injectable()
export class MessageService {
    constructor(
        private _snackBar: MatSnackBar,
        private _appService: AppService
    ) {}

    growlMessage(message: any, duration: number = 3000) {
        this._appService.setFinishedProcessing();

        if (!message || !message.severity || !message.detail) {
            return;
        }

        let action = 'OK';
        if (message.severity == 'error') {
            action = 'ERRO';
        }

        let snackBarRef = this._snackBar.open(message.detail, action, {
            duration: duration,
        });
        return snackBarRef.afterDismissed();
    }

    simpleMessage(
        message: string,
        duration: number = 3000,
        viewContainerRef: ViewContainerRef = null
    ): Observable<any> {
        this._appService.setFinishedProcessing();

        let snackBarRef = this._snackBar.open(message, null, {
            duration: duration,
            viewContainerRef: viewContainerRef,
        });
        return snackBarRef.afterDismissed();
    }

    notificationSuccessCenterMessage(
        message: string,
        duration: number = 3000,
        width: string = 'fit-content',
        height: string = '80px'
    ) {
        notify(
            {
                message: message,
                width: width,
                height: height,
                position: {
                    at: 'top center',
                    my: 'top center',
                    offset: '0 50',
                },
                shading: false,
                closeOnOutsideClick: true,
            },
            'success',
            duration
        );
    }

    errorMessage(
        e: any,
        duration: number = 5000,
        viewContainerRef: ViewContainerRef = null
    ) {
        this._appService.setFinishedProcessing();

        let message: string = '';
        if (e && e.errorMessage) {
            message = e.errorMessage;
        }

        if (message == '') {
            message = 'Atenção. Ocorreu um erro.';
        }

        let snackBarRef = this._snackBar.open(message, null, {
            duration: duration,
            viewContainerRef: viewContainerRef,
        });
        return snackBarRef.afterDismissed();
    }

    private defaultOptions(
        message: string,
        width: string,
        height: string
    ): any {
        return {
            message: message,
            width: width,
            height: height,
            shading: false,
            closeOnOutsideClick: true,
            position: {
                at: 'bottom center',
                my: 'bottom center',
                offset: '20 -100',
            },
        };
    }

    successMessage(
        message: string,
        duration: number = 3000,
        width: string = 'fit-content',
        height: string = '60px'
    ) {
        notify(
            this.defaultOptions(message, width, height),
            'success',
            duration
        );
    }

    infoMessage(
        message: string,
        duration: number = 3000,
        width: string = 'fit-content',
        height: string = '60px'
    ) {
        notify(this.defaultOptions(message, width, height), 'info', duration);
    }

    warningMessage(
        message: string,
        duration: number = 3000,
        width: string = 'fit-content',
        height: string = '60px'
    ) {
        notify(
            this.defaultOptions(message, width, height),
            'warning',
            duration
        );
    }

    warningMessageExpress(
        e: any,
        duration: number = 30000,
        width: string = 'fit-content',
        height: string = '60px'
    ) {
        this._appService.setFinishedProcessing();

        let message: string = '';
        if (e && e.errorMessage) {
            message = e.errorMessage;
        }

        if (message == '') {
            message = 'Atenção. Ocorreu um erro.';
        }

        notify(
            {
                message: message,
                width: width,
                height: height,
                shading: false,
                closeOnOutsideClick: true,
            },
            'warning',
            duration
        );
    }

    errorMessageOnlyText(
        message: string,
        duration: number = 3000,
        width: string = 'fit-content',
        height: string = '60px'
    ) {
        if (!message) {
            return;
        }

        notify(this.defaultOptions(message, width, height), 'error', duration);
    }

    errorMessageExpress(
        e: any,
        duration: number = 5000,
        width: string = 'fit-content',
        height: string = '60px'
    ) {
        this._appService.setFinishedProcessing();

        let message: string = '';
        if (e && e.errorMessage) {
            message = e.errorMessage;
        }

        if (message == '') {
            message = 'Atenção. Ocorreu um erro.';
        }

        notify(this.defaultOptions(message, width, height), 'error', duration);
    }

    multipleErrorMessageExpress(
        e: any,
        duration: number = 5000,
        width: string = 'fit-content',
        height: string = '60px'
    ) {
        this._appService.setFinishedProcessing();

        let messages: string[] = [];

        if (e && e.errorMessage) {
            messages.push(e.errorMessage);
        }

        if (e && e.errorMessages && Array.isArray(e.errorMessages)) {
            messages = messages.concat(e.errorMessages);
        }

        if (messages.length === 0) {
            messages.push('Atenção. Ocorreu um erro.');
        }

        const formattedMessage = messages.join('\n');

        notify(
            this.defaultOptions(formattedMessage, width, height),
            'error',
            duration
        );
    }

    notificationInfoMessage(
        message: string,
        duration: number = 3000,
        width: string = 'fit-content',
        height: string = '80px'
    ) {
        notify(
            {
                message: message,
                width: width,
                height: height,
                position: {
                    at: 'top right',
                    my: 'top right',
                    offset: '-25 50',
                },
                shading: false,
                closeOnOutsideClick: true,
            },
            'info',
            duration
        );
    }

    notificationWarningMessage(
        message: string,
        duration: number = 3000,
        width: string = 'fit-content',
        height: string = '50px'
    ) {
        notify(
            {
                message: message,
                width: width,
                height: height,
                position: {
                    at: 'top right',
                    my: 'top right',
                    offset: '-25 50',
                },
                shading: false,
                closeOnOutsideClick: true,
            },
            'warning',
            duration
        );
    }

    notificationSuccessMessage(
        message: string,
        duration: number = 3000,
        width: string = 'fit-content',
        height: string = '80px'
    ) {
        notify(
            {
                message: message,
                width: width,
                height: height,
                position: {
                    at: 'top right',
                    my: 'top right',
                    offset: '-25 50',
                },
                shading: false,
                closeOnOutsideClick: true,
            },
            'success',
            duration
        );
    }

    notificationErrorMessage(
        message: string,
        duration: number = 3000,
        width: string = 'fit-content',
        height: string = '80px'
    ) {
        notify(
            {
                message: message,
                width: width,
                height: height,
                position: {
                    at: 'top right',
                    my: 'top right',
                    offset: '-25 50',
                },
                shading: false,
                closeOnOutsideClick: true,
            },
            'error',
            duration
        );
    }

    notificationErrorCenterMessage(
        message: string,
        duration: number = 3000,
        width: string = 'fit-content',
        height: string = '80px'
    ) {
        notify(
            {
                message: message,
                width: width,
                height: height,
                position: {
                    at: 'top center',
                    my: 'top center',
                    offset: '0 50',
                },
                shading: false,
                closeOnOutsideClick: true,
            },
            'error',
            duration
        );
    }

    private offSet(count: number): string {
        const position = ['-25 50', '-25 140', '-25 230', '-25 320', '-25 410'];
        return position[count];
    }

    multipleNotificationMessage(
        e: any,
        duration: number = 3000,
        width: string = 'fit-content',
        height: string = '80px'
    ) {
        let i = 0;
        for (let x of e) {
            notify(
                {
                    message: x,
                    width: width,
                    height: height,
                    position: {
                        at: 'top right',
                        my: 'top right',
                        offset: this.offSet(i),
                    },
                    shading: false,
                    show: { type: 'slide', duration: 1000, from: 0, to: 1 },
                    closeOnOutsideClick: true,
                },
                'warning',
                duration
            );
            i = i + 1;
        }
    }
}
