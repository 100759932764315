import { Component, OnInit } from '@angular/core';
import { routeAnimation } from '../../../../route.animation';

@Component({
    selector: 'otk-cmp-page-area',
    templateUrl: './cmp-page-area.component.html',
    styleUrls: ['./cmp-page-area.component.scss'],
    host: {
        '[@routeAnimation]': 'true',
    },
    animations: [routeAnimation],
})
export class CmpPageAreaComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
