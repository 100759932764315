import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DxTextBoxModule } from 'devextreme-angular';

import { CmpTextboxComponent } from './cmp-textbox.component';

@NgModule({
    imports: [ReactiveFormsModule, DxTextBoxModule],
    declarations: [CmpTextboxComponent],
    exports: [CmpTextboxComponent],
})
export class TextboxModule {}
