import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { BehaviorSubject, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { fabToolbarAnimations } from './fab-toolbar.animation';

export enum FabButtonAction {
    ClearFilter = 'ClearFilter',
    ShowFilterRow = 'ShowFilterRow',
    HideFilterRow = 'HideFilterRow',
    ShowSearch = 'ShowSearch',
    HideSearch = 'HideSearch',
    ShowGroupPanel = 'ShowGroupPanel',
    HideGroupPanel = 'HideGroupPanel',
    ExportExcel = 'ExportExcel',
    ColumnChooser = 'ColumnChooser',
    Reset = 'Reset',
}

export interface FabButton {
    action: FabButtonAction;
    icon: string;
    tooltip: string;
    visible: boolean;
}

@Component({
    selector: 'otk-cmp-datagrid-fab-toolbar',
    templateUrl: './cmp-datagrid-fab-toolbar.component.html',
    styleUrls: ['./cmp-datagrid-fab-toolbar.component.scss'],
    animations: fabToolbarAnimations,
})
export class CmpDatagridFabToolbarComponent {
    // BASEADO: https://stackblitz.com/edit/angular-6-material-speed-dial-fab?file=src%2Fapp%2Fspeed-dial-fab%2Fspeed-dial-fab.component.ts

    @Input() dataGrid: DxDataGridComponent;
    @Input() gridName: string = null;

    @Input() buttonFabClearFilterVisible: boolean = true;
    @Input() buttonClearFilterVisible: boolean = true;
    @Input() buttonFilterRowVisible: boolean = false;
    @Input() buttonRowFilterVisible: boolean = true;
    @Input() buttonSearchVisible: boolean = true;
    @Input() buttonGroupPanelVisible: boolean = true;
    @Input() buttonExportVisible: boolean = true;
    @Input() buttonColumnChooserVisible: boolean = true;
    @Input() buttonClearAllFilterVisible: boolean = false;
    @Input() wordWrapEnabled: boolean = false;
    @Input() customStoreDatasource: boolean = false;

    @Output() onFilterRowVisible = new EventEmitter<boolean>();
    @Output() onGroupPanelVisible = new EventEmitter<boolean>();
    @Output() onSearchVisible = new EventEmitter<boolean>();
    @Output() onReset = new EventEmitter<boolean>();
    @Output() onClearFilter = new EventEmitter<boolean>();
    @Output() currentData = new EventEmitter<any>();

    buttons = [];
    fabTogglerState = 'inactive';

    filterRowVisible = false;
    groupPanelVisible = false;
    searchVisible = false;

    showButtonFabClearAllFilter: boolean = false;

    fabButtons: FabButton[] = [
        {
            action: FabButtonAction.ClearFilter,
            icon: 'material-icons filter_alt_off',
            tooltip: 'Limpar filtro(s)',
            visible: this.buttonClearFilterVisible,
        },
        {
            action: FabButtonAction.ShowFilterRow,
            icon: 'material-icons tune',
            tooltip: 'Exibir filtro',
            visible: this.buttonFilterRowVisible,
        },
        {
            action: FabButtonAction.HideFilterRow,
            icon: 'material-icons tune',
            tooltip: 'Esconder filtro',
            visible: this.buttonFilterRowVisible,
        },
        {
            action: FabButtonAction.ShowSearch,
            icon: 'material-icons search',
            tooltip: 'Exibir Localizar',
            visible: this.buttonSearchVisible,
        },
        {
            action: FabButtonAction.HideSearch,
            icon: 'material-icons search',
            tooltip: 'Esconder Localizar',
            visible: this.buttonSearchVisible,
        },
        {
            action: FabButtonAction.ShowGroupPanel,
            icon: 'material-icons mi-layers',
            tooltip: 'Exibir agrupamento',
            visible: this.buttonGroupPanelVisible,
        },
        {
            action: FabButtonAction.HideGroupPanel,
            icon: 'material-icons mi-layers-clear',
            tooltip: 'Limpar Agrupamento',
            visible: this.buttonGroupPanelVisible,
        },
        {
            action: FabButtonAction.ExportExcel,
            icon: 'material-icons insert-chart',
            tooltip: 'Exportar',
            visible: this.buttonExportVisible,
        },
        {
            action: FabButtonAction.ColumnChooser,
            icon: 'material-icons view-column',
            tooltip: 'Selecionar Colunas',
            visible: this.buttonColumnChooserVisible,
        },
        {
            action: FabButtonAction.Reset,
            icon: 'material-icons mi-refresh reverse',
            tooltip: 'Voltar Configuração Original',
            visible: true,
        },
    ];

    private filterApplied$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);
    private _onFilterAppliedSubscription: Subscription;
    private _onContenReadySubscription: Subscription;

    constructor() { }

    ngOnInit() {
        if (!this.dataGrid || !this.dataGrid.instance) return;

        this.dataGrid.selectedRowKeys = [];
        this.dataGrid.wordWrapEnabled = this.wordWrapEnabled;
        this.dataGrid.rowAlternationEnabled = true;
        this.dataGrid.hoverStateEnabled = true;

        this._onFilterAppliedSubscription = this.filterApplied$
            .pipe(distinctUntilChanged())
            .subscribe((r: boolean) => {
                this.showButtonFabClearAllFilter =
                    (this.buttonFabClearFilterVisible && r) ||
                    this.buttonClearAllFilterVisible;
            });

        this._onContenReadySubscription =
            this.dataGrid.onContentReady.subscribe(() => {
                this._updateFilterApplied();
            });
    }

    ngOnDestroy() {
        if (this._onFilterAppliedSubscription) {
            this._onFilterAppliedSubscription.unsubscribe();
        }
        if (this._onContenReadySubscription) {
            this._onContenReadySubscription.unsubscribe();
        }
    }

    ngAfterViewInit() { }

    ngOnChanges() {
        if (this.buttonClearAllFilterVisible) {
            this.showButtonFabClearAllFilter = this.buttonClearAllFilterVisible;
        }

        if (this.buttonFilterRowVisible) {
            let b = this._loadFilterRow();
            if (b != this.filterRowVisible) {
                this.filterRowVisible = b;
                setTimeout(
                    () => this.onFilterRowVisible.emit(this.filterRowVisible),
                    300
                );
            }
        }

        setTimeout(() => this._updateFilterApplied(), 300);
    }

    private showItems() {
        this.fabTogglerState = 'active';
        this.buttons = this.fabButtons;

        this.buttons.forEach((e: FabButton) => {
            if (e.action == FabButtonAction.ClearFilter) {
                e.visible = this.buttonClearFilterVisible;
            }

            if (e.action == FabButtonAction.ShowSearch) {
                e.visible = this.buttonSearchVisible && !this.searchVisible;
            }
            if (e.action == FabButtonAction.HideSearch) {
                e.visible = this.buttonSearchVisible && this.searchVisible;
            }

            if (e.action == FabButtonAction.ShowFilterRow) {
                e.visible =
                    this.buttonFilterRowVisible && !this.filterRowVisible;
            }
            if (e.action == FabButtonAction.HideFilterRow) {
                e.visible =
                    this.buttonFilterRowVisible && this.filterRowVisible;
            }

            if (e.action == FabButtonAction.ShowGroupPanel) {
                e.visible =
                    this.buttonGroupPanelVisible && !this.groupPanelVisible;
            }
            if (e.action == FabButtonAction.HideGroupPanel) {
                e.visible =
                    this.buttonGroupPanelVisible && this.groupPanelVisible;
            }

            if (e.action == FabButtonAction.ExportExcel) {
                e.visible = this.buttonExportVisible;
            }

            if (e.action == FabButtonAction.ColumnChooser) {
                e.visible = this.buttonColumnChooserVisible;
            }
        });

        this.buttons = this.buttons.filter((r) => r.visible);
    }

    private hideItems() {
        this.fabTogglerState = 'inactive';
        this.buttons = [];
    }

    public onToggleFab() {
        this._updateFilterApplied();

        this.buttons.length ? this.hideItems() : this.showItems();
    }

    public onClickFab(btn: FabButton) {
        if (btn.action == FabButtonAction.ClearFilter) {
            this.actionClearFilter();
        }

        if (btn.action == FabButtonAction.ShowSearch) {
            this.actionShowHideSearch(true);
        }
        if (btn.action == FabButtonAction.HideSearch) {
            this.actionShowHideSearch(false);
        }

        if (btn.action == FabButtonAction.ShowFilterRow) {
            this.actionShowHideFilterRow(true);
        }
        if (btn.action == FabButtonAction.HideFilterRow) {
            this.actionShowHideFilterRow(false);
        }

        if (btn.action == FabButtonAction.ShowGroupPanel) {
            this.actionShowHideGroupPanel(true);
        }
        if (btn.action == FabButtonAction.HideGroupPanel) {
            this.actionShowHideGroupPanel(false);
        }

        if (btn.action == FabButtonAction.ExportExcel) {
            this.actionExportExcel();
        }

        if (btn.action == FabButtonAction.ColumnChooser) {
            this.actionColumnChooser();
        }

        if (btn.action == FabButtonAction.Reset) {
            this.actionGridReset();
        }

        this.hideItems();
    }

    actionClearFilter() {
        if (!this.dataGrid || !this.dataGrid.instance) {
            return;
        }

        this.dataGrid.instance.clearFilter();
        this.actionShowHideSearch(false);
        this.actionShowHideGroupPanel(false);
        setTimeout(() => {
            this.onClearFilter.emit();
        }, 100);
    }

    private _updateFilterApplied(): void {
        if (!this.dataGrid || !this.dataGrid.instance) {
            console.warn('INVALID DATAGRID INSTANCE');
            this.filterApplied$.next(false);
            return;
        }

        const filter = this.dataGrid.instance.getCombinedFilter();
        const filterApplied = filter && filter.length > 0 ? true : false;

        this.filterApplied$.next(filterApplied);

        if (this.customStoreDatasource) {
            // console.log('CUSTOM STORE DATASOURCE ->', this.customStoreDatasource);
            return;
        }

        const dataSource = this.dataGrid.instance.getDataSource();
        if (dataSource) {
            const filterExpr = this.dataGrid.instance.getCombinedFilter(true);
            const loadOptions = dataSource.loadOptions();

            dataSource
                .store()
                .load({
                    filter: filterExpr,
                    sort: loadOptions.sort,
                    group: loadOptions.group,
                    userData: { filterApplied: true }
                })
                .then(
                    (data: any) => {
                        this.currentData.next(data);
                    },
                    () => {
                        console.error('Error getting current data.');
                        this.currentData.next([]);
                    }
                );
        }

        // NOTE: Backlog 8824 (configuração de colunas VISIBLE)
        // https://otksistemas.visualstudio.com/Frontend.OTKWEB/_workitems/edit/8824
        // this.dataGrid.columns.forEach((r: DevExpress.ui.dxDataGridColumn) => {
        //     if (r.visible) {
        //         console.log('column VISIBLE ->', r.dataField);
        //     } else {
        //         console.log('column INVISIBLE ->', r.dataField);
        //     }

        //     if (r.dataField == 'inscricaoEstadual') {
        //         console.log('column inscricaoEstadual CHANGE->', r);
        //         this.dataGrid.instance.columnOption(
        //             r.dataField,
        //             'visible',
        //             true
        //         );
        //     }
        // });
    }

    private _loadFilterRow(): boolean {
        const b = localStorage.getItem(`${this.gridName}_FILTER_ROW`);
        return b == 'true';
    }
    private _saveFilterRow(b: boolean): void {
        localStorage.setItem(
            `${this.gridName}_FILTER_ROW`,
            this.filterRowVisible.toString()
        );
    }

    actionShowHideFilterRow(b: boolean) {
        if (!this.dataGrid || !this.dataGrid.instance) return;

        this.filterRowVisible = b;

        this.dataGrid.instance.clearFilter();
        this.onFilterRowVisible.emit(this.filterRowVisible);
        this._saveFilterRow(this.filterRowVisible);
    }

    actionShowHideSearch(b: boolean) {
        if (!this.dataGrid || !this.dataGrid.instance) return;

        this.searchVisible = b;

        this.dataGrid.instance.clearFilter();
        this.onSearchVisible.emit(this.searchVisible);
    }

    actionShowHideGroupPanel(b: boolean) {
        if (!this.dataGrid || !this.dataGrid.instance) return;

        this.groupPanelVisible = b;

        this.dataGrid.instance.clearGrouping();
        this.onGroupPanelVisible.emit(this.groupPanelVisible);
    }

    actionExportExcel() {
        if (!this.dataGrid || !this.dataGrid.instance) return;

        this.dataGrid.instance.exportToExcel(false);
    }

    actionColumnChooser() {
        if (!this.dataGrid || !this.dataGrid.instance) return;

        this.dataGrid.instance.showColumnChooser();
    }

    actionGridReset() {
        if (!this.dataGrid || !this.dataGrid.instance) return;

        if (this.gridName) {
            sessionStorage.removeItem(this.gridName);
            localStorage.removeItem(this.gridName);
        }

        this.dataGrid.instance.state(null);
        this.actionShowHideSearch(false);
        this.actionShowHideGroupPanel(false);
        this.onReset.emit();
    }
}
