import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'otk-message-no-register',
    templateUrl: './message-no-register.component.html',
    styleUrls: ['./message-no-register.component.scss'],
})
export class MessageNoRegisterComponent implements OnInit {
    @Input() mensagem1: string = 'Nenhum registro foi encontrado.';
    @Input() mensagem2: string = 'Verifique se há algum filtro aplicado.';

    constructor() {}

    ngOnInit() {}
}
