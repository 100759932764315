import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DxTextBoxComponent } from 'devextreme-angular';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
    selector: 'otk-cmp-textbox-email',
    templateUrl: './cmp-textbox-email.component.html',
    styleUrls: ['./cmp-textbox-email.component.scss'],
})
export class CmpTextboxEmailComponent implements OnInit {
    @Input() label: string = 'E-mail';
    @Input() control: FormControl = new FormControl(null, []);
    @Input() placeholder: string = '';
    @Input() width: string = '100%';
    @Input() focus$: Subject<boolean> = new Subject<boolean>();
    @Input() help: string = null;
    @Input() widthHelp: string = '300px';
    @ViewChild('ipt') inputEmail: DxTextBoxComponent;

    popoverVisible: boolean;

    constructor() {}

    ngOnInit() {
        this.focus$.pipe(take(1)).subscribe(() => {
            setTimeout(() => {
                if (this.inputEmail && this.inputEmail.instance) {
                    this.inputEmail.instance.focus();
                }
            }, 800);
        });
    }

    ngOnDestroy() {}

    ngOnChanges() {}

    togglePopover() {
        this.popoverVisible = !this.popoverVisible;
    }
}
