import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DxDateBoxModule, DxPopoverModule, DxTemplateModule } from 'devextreme-angular';

import { CmpDateboxLabelComponent } from './cmp-datebox-label.component';


@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        DxPopoverModule,
        DxTemplateModule,

        DxDateBoxModule,
    ],
    declarations: [
        CmpDateboxLabelComponent
    ],
    exports: [
        CmpDateboxLabelComponent,
    ]
})
export class DateboxLabelModule { }
