import {
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Output,
    Attribute,
    Input,
} from '@angular/core';

@Directive({
    selector: '[otkClickOutside]',
})
export class ClickOutsideDirective {
    constructor(private _elementRef: ElementRef) {}

    @Input() public otkClickOutsideActive: boolean = false;
    @Output() public otkClickOutside = new EventEmitter<MouseEvent>();

    @HostListener('document:click', ['$event', '$event.target'])
    public onClick(event: MouseEvent, targetElement: HTMLElement): void {
        if (!this.otkClickOutsideActive) {
            return;
        }

        if (!targetElement) {
            return;
        }

        const clickedInside =
            this._elementRef.nativeElement.contains(targetElement);
        if (!clickedInside) {
            this.otkClickOutside.emit(event);
        }
    }
}
