import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'otk-cmp-close-button',
    templateUrl: './cmp-close-button.component.html',
    styleUrls: ['./cmp-close-button.component.scss'],
})
export class CmpCloseButtonComponent implements OnInit {
    @Input() enabled: boolean = true;

    constructor() {}

    ngOnInit() {}
}
