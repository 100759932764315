import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { hasRequiredField } from '../../../app.functions';


@Component({
    selector: 'otk-cmp-labelbox',
    templateUrl: './cmp-labelbox.component.html',
    styleUrls: ['./cmp-labelbox.component.scss']
})
export class CmpLabelboxComponent implements OnInit {

    @Input() label: string = null;
    @Input() control: FormControl = new FormControl(null, []);
    @Input() help: string = null;
    @Input() widthHelp: string = "300px";

    popoverVisible: boolean;

    constructor(
    ) {
    }

    ngOnInit() {
    }

    ngOnDestroy() {
    }

    ngOnChanges() {
    }

    get _caption(): string {
        return this.label.replace(/\*/g, ' ');
    }

    get _captionLabel(): string {
        if (this.control && hasRequiredField(this.control)) {
            let t = this.label.replace(/\*/g, ' ');
            return `${t} *`;
        }
        return this.label;
    }

    togglePopover() {
        this.popoverVisible = !this.popoverVisible;
    }
}
