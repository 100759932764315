import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { environment } from '../../environments/environment';
import { reducers } from './app.reducer';

// import AppStoreModule in the AppModule after router module

@NgModule({
    imports: [
        StoreModule.forRoot(reducers),
        EffectsModule.forRoot([]),
        StoreRouterConnectingModule,
        !environment.production
            ? StoreDevtoolsModule.instrument({ maxAge: 50 })
            : [],
    ],
    exports: [StoreModule],
})
export class AppStoreModule {}
