import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
    AccordionModule,
    AutoCompleteModule,
    CalendarModule,
    DataTableModule,
    FieldsetModule,
    GrowlModule,
    InputMaskModule,
    MultiSelectModule,
    OverlayPanelModule,
    SpinnerModule,
} from 'primeng/primeng';
import { TableModule } from 'primeng/table';
import { TreeTableModule } from 'primeng/treetable';

@NgModule({
    imports: [CommonModule],
    declarations: [],
    exports: [
        AccordionModule,
        AutoCompleteModule,
        CalendarModule,
        DataTableModule,
        FieldsetModule,
        GrowlModule,
        InputMaskModule,
        MultiSelectModule,
        OverlayPanelModule,
        SpinnerModule,
        TableModule,
        TreeTableModule,
    ],
})
export class PrimeNGComponentsModule {}
