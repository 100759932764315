import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'otk-cmp-help-button',
    templateUrl: './cmp-help-button.component.html',
    styleUrls: ['./cmp-help-button.component.scss'],
})
export class CmpHelpButton implements OnInit {
    @Input() enabled: boolean = true;
    @Input() toolTip: string = 'Ajuda';

    constructor() {}

    ngOnInit() {}
}
