import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';

import { StateModeEnum } from './../../../../app.enums';
import { AppHelper } from './../../../../app.helper';

@Component({
    selector: 'otk-cmp-textbox-disabled-label-button',
    templateUrl: './cmp-textbox-disabled-label-button.component.html',
    styleUrls: ['./cmp-textbox-disabled-label-button.component.scss'],
})
export class CmpTextboxDisabledLabelButtonComponent implements OnInit {
    @Input() label: string = null;
    @Input() help: string = null;
    @Input() value: any = null;
    @Input() width: string = '100%';
    @Input() widthHelp: string = '300px';
    @Input() noMarginBottom: boolean = false;
    @Input() searchVisible: boolean = false;
    @Input() invisibleWhenNotHasValue: boolean = false;
    @Input() textContent: string = '';
    @Input() state$: BehaviorSubject<StateModeEnum> =
        new BehaviorSubject<StateModeEnum>(StateModeEnum.browse);

    @Output() searchClick = new EventEmitter<any>();

    componentVisible: boolean = true;
    popoverVisible: boolean = false;
    enabledState: boolean = false;

    control: FormControl = new FormControl(null, []);

    private _stateSubscription: Subscription;

    constructor() {}

    ngOnInit() {
        this._stateSubscription = this.state$.subscribe((r) => {
            this.enabledState = AppHelper.isBrowseSelectedMode(r);
        });
    }

    ngOnDestroy() {
        this._stateSubscription.unsubscribe();
    }

    ngOnChanges() {
        AppHelper.setCtrlValue(this.control, this.value);
        AppHelper.setCtrlEnable(this.control, false);

        if (this.invisibleWhenNotHasValue) {
            this.componentVisible = this.value ? true : false;
        }
    }

    togglePopover() {
        this.popoverVisible = !this.popoverVisible;
    }

    actionSearchClick(e: any): void {
        this.searchClick.emit(e);
    }
}
