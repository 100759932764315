import { NgModule } from '@angular/core';

import { CadastrosAuthClienteService } from './cadastros-auth-cliente.service';
import { CadastrosAuthFornecedorService } from './cadastros-auth-fornecedor.service';
import { CadastrosAuthProdutoService } from './cadastros-auth-produto.service';
import { CadastrosAuthService } from './cadastros-auth.service';

@NgModule({
    providers: [
        CadastrosAuthService,
        CadastrosAuthProdutoService,
        CadastrosAuthClienteService,
        CadastrosAuthFornecedorService,
    ],
})
export class CadastrosAuthModule {}
