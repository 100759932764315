import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
    selector: 'app-error-dialog',
    templateUrl: './error-dialog.component.html',
    styleUrls: ['./error-dialog.component.scss'],
})
export class ErrorDialogComponent implements OnInit {
    public title: string;
    public message: string;

    constructor(public dialogRef: MatDialogRef<ErrorDialogComponent>) {}

    ngOnInit() {}
}
