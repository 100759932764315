import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DevextremeComponentsModule } from '../devextreme-components.module';
import { MaterialComponentsModule } from '../material-components.module';
import { PrimeNGComponentsModule } from '../primeng-components.module';
import { PerfectScrollbarComponentModule } from './../perfect-scrollbar-component.module';
import { AreasComponentsModule } from './components/areas/areas-components.module';
import { ButtonsComponentsModule } from './components/buttons/buttons-components.module';
import { CheckboxesComponentsModule } from './components/checkboxes/checkboxes-components.module';
import { DatagridComponentsModule } from './components/datagrid/datagrid-components.module';
import { DateboxsComponentsModule } from './components/datebox/dateboxs-components.module';
import { DialogsComponentsModule } from './components/dialogs/dialogs-components.module';
import { InputsComponentsModule } from './components/inputs/inputs-components.module';
import { LabelboxsComponentsModule } from './components/labelbox/labelboxs-components.module';
import { NumberboxsComponentsModule } from './components/numberbox/numberboxs-components.module';
import { MessageNoRegisterModule } from './components/panels/message-no-register/message-no-register.module';
import { RadiogroupsComponentsModule } from './components/radiogroup/radiogroup-label/radiogroups-components.module';
import { SelectboxsComponentsModule } from './components/selectbox/selectboxs-components.module';
import { SlidesComponentsModule } from './components/slides/slides-components.module';
import { SwitchComponentsModule } from './components/switch/switch-components.module';
import { TagboxDisabledLabelModule } from './components/tagbox/tagbox-disabled-label/tagbox-disabled-label.module';
import { TextboxsComponentsModule } from './components/textbox/textboxs-components.module';

@NgModule({
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        FlexLayoutModule,
        MaterialComponentsModule,
        PrimeNGComponentsModule,
        PerfectScrollbarComponentModule,

        MessageNoRegisterModule,
        AreasComponentsModule,
        ButtonsComponentsModule,
        CheckboxesComponentsModule,
        DialogsComponentsModule,
        InputsComponentsModule,
        SlidesComponentsModule,

        // DEVEXPRESS
        DevextremeComponentsModule,
        DatagridComponentsModule,
        LabelboxsComponentsModule,
        NumberboxsComponentsModule,
        RadiogroupsComponentsModule,
        SelectboxsComponentsModule,
        TagboxDisabledLabelModule,
        TextboxsComponentsModule,
        DateboxsComponentsModule,
        SwitchComponentsModule,
    ],
})
export class UIComponentsModule {}
