import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';

import { StateModeEnum } from '../../../../app.enums';
import { AppHelper } from '../../../../app.helper';
import { MessageService } from '../../../../shared-services/message/message.service';

@Component({
    selector: 'otk-cmp-save-cancel-button',
    templateUrl: './cmp-save-cancel-button.component.html',
    styleUrls: ['./cmp-save-cancel-button.component.scss'],
})
export class CmpSaveCancelButtonComponent implements OnInit, OnDestroy {
    visible: boolean = true;
    enabled: boolean = false;
    validateForm: boolean = false;
    isInsert: boolean = false;

    private stateSubscription: Subscription;
    private validSubscription: Subscription;

    @Input() state$: BehaviorSubject<StateModeEnum> =
        new BehaviorSubject<StateModeEnum>(StateModeEnum.undefined);
    @Input() valid$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );
    @Input() form: FormGroup = null;
    @Input() captionAdd: string = 'Salvar';
    @Input() captionEdit: string = 'Salvar';

    @Output() saveClick: EventEmitter<any> = new EventEmitter();
    @Output() cancelClick: EventEmitter<any> = new EventEmitter();
    @Output() invalidFormEvent: EventEmitter<any> = new EventEmitter();

    constructor(private _message: MessageService) {}

    ngOnInit() {
        this.stateSubscription = this.state$.subscribe((r) => {
            this.visible = r == StateModeEnum.insert || r == StateModeEnum.edit;
            this.isInsert = r == StateModeEnum.insert;
        });

        this.validSubscription = this.valid$.subscribe((r) => {
            this.enabled = r;
        });

        if (this.form) {
            this.validateForm = true;
        }
    }

    ngOnDestroy() {
        this.stateSubscription.unsubscribe();
        this.validSubscription.unsubscribe();
    }

    actionButtonSaveClick($event: any): void {
        if (this.validateForm) {
            if (!this.valid$.value) {
                this.invalidFormEvent.emit();
                this._message.notificationErrorMessage(
                    'Por favor, verifique os campos destacados.'
                );
                AppHelper.markControlsDirtyAndTouched(this.form);
                AppHelper.logControlsInvalid(this.form);
                return;
            }
        }

        this.saveClick.emit($event);
    }

    actionButtonCancelClick($event: any): void {
        this.cancelClick.emit($event);
    }
}
