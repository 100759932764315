import { Injectable } from '@angular/core';
import { MediaChange, ObservableMedia } from '@angular/flex-layout';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class AppService {
    public applicationProcessing$: Subject<boolean> = new Subject<boolean>();
    public mediaIsMobile$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);

    public codigoFilialDashboard$: BehaviorSubject<number> =
        new BehaviorSubject<number>(0);

    constructor(private _media: ObservableMedia) {
        this._media.asObservable().subscribe((change: MediaChange) => {
            // let isMobile = (change.mqAlias == 'xs') || (change.mqAlias == 'sm');
            let isMobile = change.mqAlias == 'xs';

            this.mediaIsMobile$.next(isMobile);
        });
    }

    setIsProcessing() {
        this.applicationProcessing$.next(true);
    }

    setFinishedProcessing() {
        this.applicationProcessing$.next(false);
    }

    get isMobile(): boolean {
        return this.mediaIsMobile$.value;
    }

    get isNotMobile(): boolean {
        return !this.isMobile;
    }

    get isDesktop(): boolean {
        return !this.isMobile;
    }
}
