import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

import { StateModeEnum } from '../../../../app.enums';

@Component({
    selector: 'otk-cmp-title-divider-area',
    templateUrl: './cmp-title-divider-area.component.html',
    styleUrls: ['./cmp-title-divider-area.component.scss'],
})
export class CmpTitleDividerAreaComponent implements OnInit, OnDestroy {
    backButtonVisible: boolean = false;
    refreshButtonVisible: boolean = false;

    private _isBrowseMode: boolean = false;
    private _stateSubscription: Subscription;

    @Input() title: string = 'XXXX';
    @Input() subTitle: string = '';
    @Input() backButton: boolean = false;
    @Input() refreshButton: boolean = false;
    @Input() titleVisibleMobile: boolean = false;
    @Input() state$: BehaviorSubject<StateModeEnum> =
        new BehaviorSubject<StateModeEnum>(StateModeEnum.browse);
    @Input() help: string = null;
    @Input() widthHelp: string = '300px';

    @Output() refreshClick = new EventEmitter<any>();

    popoverVisible: boolean;

    constructor() {}

    ngOnInit() {
        this._stateSubscription = this.state$.subscribe((r) => {
            this._isBrowseMode =
                r == StateModeEnum.browse ||
                r == StateModeEnum.selected ||
                r == StateModeEnum.undefined;
            this._checkButtons();
        });
    }
    ngOnDestroy(): void {
        this._stateSubscription.unsubscribe();
    }

    ngOnChanges(): void {
        this._checkButtons();
    }

    private _checkButtons(): void {
        this.backButtonVisible = this.backButton == true && this._isBrowseMode;
        this.refreshButtonVisible =
            this.refreshButton == true && this._isBrowseMode;
    }

    actionBtnRefresh(): void {
        this.refreshClick.emit(true);
    }

    togglePopover() {
        this.popoverVisible = !this.popoverVisible;
    }
}
