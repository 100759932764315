import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-confirm-motivo-delete-dialog',
    templateUrl: './confirm-motivo-delete-dialog.component.html',
    styleUrls: ['./confirm-motivo-delete-dialog.component.scss'],
})
export class ConfirmMotivoDeleteDialogComponent implements OnInit {
    public title: string;
    public message: string;

    motivo: FormControl = new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(300),
    ]);
    exclusao: boolean = true;
    campo: string = 'Motivo';

    constructor(
        public dialogRef: MatDialogRef<ConfirmMotivoDeleteDialogComponent>
    ) {}

    ngOnInit() {}

    actionBtnOk() {
        if (this.motivo.valid) {
            this.dialogRef.close({ r: true, motivo: this.motivo.value });
        }
    }

    actionBtnFechar(): void {
        this.dialogRef.close(false);
    }
}
