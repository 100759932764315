import { DxPopoverModule } from 'devextreme-angular';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MaterialComponentsModule } from '../../../material-components.module';
import { ButtonsComponentsModule } from '../buttons/buttons-components.module';
import { CmpButtonsAreaComponent } from './buttons-area/cmp-buttons-area.component';
import { CmpContentAreaTabComponent } from './content-area-tab/cmp-content-area-tab.component';
import { CmpContentAreaComponent } from './content-area/cmp-content-area.component';
import { CmpDivAreaComponent } from './div-area/cmp-div-area.component';
import { CmpFormAreaComponent } from './form-area/cmp-form-area.component';
import { CmpPageAreaDivComponent } from './page-area-div/cmp-page-area-div.component';
import { CmpPageAreaFullComponent } from './page-area-full/cmp-page-area-full.component';
import { CmpPageAreaComponent } from './page-area/cmp-page-area.component';
import { CmpTitleAreaComponent } from './title-area/cmp-title-area.component';
import { CmpTitleDividerAreaComponent } from './title-area/cmp-title-divider-area.component';
import { CmpTitleContentAreaComponent } from './title-area/cmp-title-content-area.component';
import { CmpTitleAreaOrdemDeCompraComponent } from './title-area-ordem-de-compra/cmp-title-area-ordem-de-compra.component';
import { CmpTitleContentAreaOrdemDeCompraComponent } from './title-area-ordem-de-compra/cmp-title-content-area-ordem-de-compra.component';

@NgModule({
    imports: [
        CommonModule,
        MaterialComponentsModule,
        DxPopoverModule,

        ButtonsComponentsModule,
    ],
    exports: [
        CmpButtonsAreaComponent,
        CmpContentAreaComponent,
        CmpContentAreaTabComponent,
        CmpDivAreaComponent,
        CmpFormAreaComponent,
        CmpPageAreaComponent,
        CmpPageAreaDivComponent,
        CmpPageAreaFullComponent,
        CmpTitleAreaComponent,
        CmpTitleDividerAreaComponent,
        CmpTitleContentAreaComponent,
        CmpTitleAreaOrdemDeCompraComponent,
        CmpTitleContentAreaOrdemDeCompraComponent,
    ],
    declarations: [
        CmpButtonsAreaComponent,
        CmpContentAreaComponent,
        CmpContentAreaTabComponent,
        CmpDivAreaComponent,
        CmpFormAreaComponent,
        CmpPageAreaComponent,
        CmpPageAreaDivComponent,
        CmpPageAreaFullComponent,
        CmpTitleAreaComponent,
        CmpTitleDividerAreaComponent,
        CmpTitleContentAreaComponent,
        CmpTitleAreaOrdemDeCompraComponent,
        CmpTitleContentAreaOrdemDeCompraComponent,
    ],
})
export class AreasComponentsModule {}
