import { Injectable, ViewContainerRef } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';
import { Observable, Subject } from 'rxjs';

import { ContratacaoQuantidadeRequisitanteDialogComponent } from './contratacao-quantidade-requisitante-dialog.component';

@Injectable()
export class ContratacaoQuantidadeRequisitanteService {
    constructor(private _dialog: MatDialog) {}

    message(
        title: string,
        message: string,
        viewContainerRef: ViewContainerRef
    ): Observable<boolean> {
        let dialogRef: MatDialogRef<ContratacaoQuantidadeRequisitanteDialogComponent>;

        let config = new MatDialogConfig();
        config.viewContainerRef = viewContainerRef;
        config.disableClose = true;
        config.width = '450px';

        dialogRef = this._dialog.open(
            ContratacaoQuantidadeRequisitanteDialogComponent,
            config
        );

        dialogRef.componentInstance.title = title;
        dialogRef.componentInstance.message = message;

        let result = new Subject<boolean>();

        dialogRef.afterClosed().subscribe((r: any) => {
            // this._dialog.closeAll();  //RCO: Fecha todos os formulários (inclusive se for Parent = Modal)
            dialogRef = null;
            return result.next(r);
        });
        return result;
    }
}
