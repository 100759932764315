import { NgModule } from '@angular/core';
import { NumberboxDisabledLabelModule } from './numberbox-disabled-label/numberbox-disabled-label.module';
import { NumberboxLabelModule } from './numberbox-label/numberbox-label.module';
import { NumberboxSearchModule } from './numberbox-search/numberbox-search.module';
import { NumberboxSpinnerLabelModule } from './numberbox-spinner-label/numberbox-spinner-label.module';
import { NumberboxModule } from './numberbox/numberbox.module';

@NgModule({
    exports: [
        NumberboxModule,
        NumberboxSearchModule,
        NumberboxLabelModule,
        NumberboxDisabledLabelModule,
        NumberboxSpinnerLabelModule,
    ],
})
export class NumberboxsComponentsModule {}
