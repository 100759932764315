import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
    DxNumberBoxModule,
    DxPopoverModule,
    DxTemplateModule,
} from 'devextreme-angular';

import { NumberboxModule } from '../numberbox/numberbox.module';
import { CmpNumberboxDisabledLabelComponent } from './cmp-numberbox-disabled-label.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        DxPopoverModule,
        DxTemplateModule,
        DxNumberBoxModule,
        NumberboxModule,
    ],
    declarations: [CmpNumberboxDisabledLabelComponent],
    exports: [CmpNumberboxDisabledLabelComponent],
})
export class NumberboxDisabledLabelModule {}
