export class HandleErrorModel {
    status: string;
    statusText: string;
    errorMessage: string;
    errorMessages: string[] = [];

    constructor(error: any) {
        this.status = '';
        this.statusText = '';

        this.errorMessage =
            'Ocorreu um erro interno no sistema. Por favor, ' +
            'tente executar a operação novamente e se o erro persistir, ' +
            'entre em contato com nosso suporte técnico.';

        if (!error || !error.status || !error.statusText) {
            console.log('HandleError Invalid = ', error);
            return;
        }

        if (error) {
            if (error.message == 'Timeout has occurred') {
                console.log('Timeout - Esgotou o tempo máximo da requisição.');
            }
        }

        let e = null;
        if (error.status && error.statusText && error.error) {
            // Error Effect NgStore
            // e = JSON.parse(error.error); --> utilizado no NgStore v4
            e = error.error; // --> utilizado no NgStore v6 (Pipe)
        } else {
            if (error.error) {
                e = error.json();
            }
        }

        if (e && e.error && e.error_description) {
            // Invalid User (Authentication)
            if (e.error == 'invalid_grant') {
                this.errorMessage = 'E-mail ou senha inválidos.';
                if (e.error_description) {
                    this.errorMessage = e.error_description.replace(/\- /g, '');
                }
                return;
            }

            // Authentication error
            if (e.error == 'invalid_authentication') {
                this.errorMessage = 'E-mail ou senha inválidos.';
                if (e.error_description) {
                    this.errorMessage = e.error_description.replace(/\- /g, '');
                }
                return;
            }

            // Company Blocked
            if (e.error == 'invalid_company_blocked') {
                this.status = e.error;
                this.errorMessage =
                    'Acesso bloqueado. Entre em contato com o nosso suporte.';
                return;
            }

            // Token Client: Invalid ClientId
            if (e.error == 'invalid_clientId') {
                this.errorMessage = 'Client Id inválido.';
                return;
            }
            // Token Client: Invalid Secret
            if (e.error == 'invalid_clientSecret') {
                this.errorMessage = 'Client Secret inválido.';
                return;
            }
            // Token Client: Invalid Username
            if (e.error == 'username_not_found') {
                this.errorMessage = 'Usuário inválido.';
                if (e.error_description) {
                    this.errorMessage = `Usuário Inválido: ${e.error_description}`;
                }
                return;
            }
        }

        this.status = error.status;
        this.statusText = error.statusText;
        this.errorMessage = e ? e.message : '';
        this.errorMessages = [];

        // Tratar HttpStatus
        if (this.status) {
            // 400 - BAD REQUEST
            if (this.status == '400') {
                if (!this.errorMessage) {
                    if (
                        error.error &&
                        error.error.title &&
                        error.error.title ==
                            'One or more validation errors occurred.'
                    ) {
                        this.errorMessage =
                            'Ocorreu um erro na validação do dados. Não será possível executar essa operação.';
                    }
                }
            }

            // 401 - UNAUTHORIZED
            if (this.status == '401') {
                this.errorMessage =
                    'Sua sessão expirou ou há um problema de acesso. Por favor, faça login novamente e então tente realizar sua operação. ' +
                    'Se o problema persistir, entre em contato com nosso suporte técnico.';
            }

            // 404 - NOT FOUND
            if (this.status == '404') {
                this.errorMessage = 'Registro não localizado.';
            }

            // 500 - INTERNAL SERVER ERROR
            if (this.status == '500') {
                if (!this.errorMessage) {
                    this.errorMessage =
                        'Ocorreu um erro interno no sistema. Por favor, ' +
                        'tente executar a operação novamente e se o erro persistir, ' +
                        'entre em contato com nosso suporte técnico.';
                }
            }
        }

        // Tratar mensagens de erros/notificações da aplicação. Retornar a primeira mensagem.
        this.errorMessages = [];
        if (e && e.errors && e.errors.length > 0) {
            for (let i = 0; i < e.errors.length; i++) {
                this.errorMessages.push(e.errors[i].value);
            }

            if (this.errorMessages && this.errorMessages.length > 0) {
                this.status = error.status;
                this.statusText = error.statusText;
                this.errorMessage = this.errorMessages.shift();
            }
        }
    }
}
