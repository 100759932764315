import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DxDateBoxComponent } from 'devextreme-angular';

import { hasRequiredField } from '../../../../app.functions';
import { AppHelper } from '../../../../app.helper';

@Component({
    selector: 'otk-cmp-datebox-range-label',
    templateUrl: './cmp-datebox-range-label.component.html',
    styleUrls: ['./cmp-datebox-range-label.component.scss'],
})
export class CmpDateboxRangeLabelComponent implements OnInit {
    @Input() label: string = null;
    @Input() help: string = null;
    @Input() placeholder: string = '';
    @Input() control1: FormControl = new FormControl(null, []);
    @Input() control2: FormControl = new FormControl(null, []);
    @Input() minDate: string = '1980/1/1';
    @Input() maxDate: string = '2050/12/31';
    @Input() width: string = '130px';
    @Input() widthHelp: string = '300px';
    @Input() showClearButton: boolean = true;
    @Input() validationMessageMode: string = 'always';
    @Input() errorRequired: string = `Deve ser informado.`;
    @Input() errorInvalid: string = `Data inválida.`;
    @Input() maskInvalidMessage: string = `Valor inválido.`;
    @Input() noMarginBottom: boolean = false;

    popoverVisible: boolean;

    @ViewChild(DxDateBoxComponent) dateBox: DxDateBoxComponent;

    constructor() {}

    ngOnInit() {
        this.control1.valueChanges.subscribe((r: any) => {
            if (!r) {
                return;
            }

            if (this.control2.value < r) {
                AppHelper.setCtrlValue(this.control2, r, true);
            }
        });

        this.control2.valueChanges.subscribe((r: any) => {
            if (!r) {
                return;
            }

            if (this.control1.value > r) {
                AppHelper.setCtrlValue(this.control1, r, true);
            }
        });
    }

    ngOnDestroy() {}

    ngOnChanges() {}

    get _caption(): string {
        return this.label.replace(/\*/g, ' ');
    }

    get _captionLabel(): string {
        if (this.control1 && hasRequiredField(this.control1)) {
            let t = this.label.replace(/\*/g, ' ');
            return `${t} *`;
        }
        return this.label;
    }

    isValid1(): boolean {
        if (!this.control1) {
            return false;
        }

        if (this.control1.pristine && this.control1.untouched) {
            return true;
        }

        return !this.control1.invalid;
    }

    validationError1(): any {
        if (!this.control1) {
            return { message: null };
        }

        if (!this.control1.errors) {
            return { message: null };
        } else {
            if (this.control1.errors.required) {
                return { message: this.errorRequired };
            } else if (this.control1.errors.invalidMask) {
                return { message: this.maskInvalidMessage };
            } else {
                return { message: `Inválido.` };
            }
        }
    }

    actionValueChanged1(e: any) {
        if (e && e.value && this.dateBox) {
            if (!this.dateBox.isValid) {
                this.control1.setErrors({ invalidMask: true });
            }
        }
    }

    isValid2(): boolean {
        if (!this.control2) {
            return false;
        }

        if (this.control2.pristine && this.control2.untouched) {
            return true;
        }

        return !this.control2.invalid;
    }

    validationError2(): any {
        if (!this.control2) {
            return { message: null };
        }

        if (!this.control2.errors) {
            return { message: null };
        } else {
            if (this.control2.errors.required) {
                return { message: this.errorRequired };
            } else if (this.control2.errors.invalidMask) {
                return { message: this.maskInvalidMessage };
            } else {
                return { message: `Inválido.` };
            }
        }
    }

    actionValueChanged2(e: any) {
        if (e && e.value && this.dateBox) {
            if (!this.dateBox.isValid) {
                this.control2.setErrors({ invalidMask: true });
            }
        }
    }

    togglePopover() {
        this.popoverVisible = !this.popoverVisible;
    }
}
