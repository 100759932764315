import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AutenticadoGuard } from './auth/guards/autenticado.guard';

const routes: Routes = [
    {
        path: 'login',
        loadChildren: './core/login/login.module#LoginModule',
    },
    {
        path: 'forgot-password',
        loadChildren:
            './core/forgot-password/forgot-password.module#ForgotPasswordModule',
    },
    {
        path: 'signup',
        loadChildren: './core/signup/signup.module#SignupModule',
    },
    {
        path: 'client-signin',
        loadChildren:
            './core/client-signin/client-signin.module#ClientSigninModule',
    },
    {
        path: 'viewer',
        loadChildren:
            './core/client-viewer/client-viewer.module#ClientViewerModule',
    },
    {
        path: '',
        canLoad: [AutenticadoGuard],
        loadChildren: './core/main/main.module#MainModule',
    },
    {
        path: '**',
        redirectTo: '/login',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [],
})
export class RoutingModule {}
