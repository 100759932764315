import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DxTagBoxModule } from 'devextreme-angular';

import { CmpTagboxDisabledLabelComponent } from './cmp-tagbox-disabled-label.component';

@NgModule({
    imports: [CommonModule, DxTagBoxModule],
    declarations: [CmpTagboxDisabledLabelComponent],
    exports: [CmpTagboxDisabledLabelComponent],
    providers: [],
})
export class TagboxDisabledLabelModule {}
