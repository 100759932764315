import { Subscription, BehaviorSubject } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';

import { StateModeEnum } from '../../../../app.enums';

@Component({
    selector: 'otk-cmp-add-button',
    templateUrl: './cmp-add-button.component.html',
    styleUrls: ['./cmp-add-button.component.scss'],
})
export class CmpAddButtonComponent implements OnInit {
    visible: boolean = false;
    private _stateSubscription: Subscription;

    @Input() tooltip: string = 'Novo';
    @Input() state$: BehaviorSubject<StateModeEnum> =
        new BehaviorSubject<StateModeEnum>(StateModeEnum.browse);

    constructor() {}

    ngOnInit() {
        this._stateSubscription = this.state$.subscribe((r) => {
            this.visible =
                r == StateModeEnum.browse || r == StateModeEnum.selected;
        });
    }

    ngOnDestroy(): void {
        this._stateSubscription.unsubscribe();
    }
}
