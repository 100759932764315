import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'otk-cmp-timeline-button',
    templateUrl: './cmp-timeline-button.component.html',
    styleUrls: ['./cmp-timeline-button.component.scss'],
})
export class CmpTimelineButtonComponent implements OnInit {
    @Input() enabled: boolean = true;
    @Input() tooltip: string = 'Histórico';

    constructor() {}

    ngOnInit() {}
}
