import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'otk-cmp-slide-input',
    templateUrl: './cmp-slide-input.component.html',
    styleUrls: ['./cmp-slide-input.component.scss'],
})
export class CmpSlideInputComponent implements OnInit {
    @Input() caption: string = '';
    @Input() help: string = null;
    @Input() control: FormControl = new FormControl(null, []);

    popoverVisible: boolean;

    constructor() {}

    ngOnInit() {}

    togglePopover() {
        this.popoverVisible = !this.popoverVisible;
    }
}
