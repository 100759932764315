import { NgModule } from '@angular/core';

import { DateboxDisabledLabelModule } from './datebox-disabled-label/datebox-disabled-label.module';
import { DateboxLabelModule } from './datebox-label/datebox-label.module';
import { DateboxMonthYearLabelModule } from './datebox-month-year-label/datebox-month-year-label.module';
import { DateboxRangeLabelModule } from './datebox-range-label/datebox-range-label.module';
import { DateboxTimeDisabledLabelModule } from './datebox-time-disabled-label/datebox-time-disabled-label.module';
import { DateboxTimeLabelModule } from './datebox-time-label/datebox-time-label.module';
import { DateboxTimeOnlyLabelModule } from './datebox-time-only-label/datebox-time-only-label.module';
import { DateboxYearLabelModule } from './datebox-year-label/datebox-year-label.module';

@NgModule({
    exports: [
        DateboxLabelModule,
        DateboxMonthYearLabelModule,
        DateboxYearLabelModule,
        DateboxDisabledLabelModule,
        DateboxTimeLabelModule,
        DateboxTimeOnlyLabelModule,
        DateboxTimeDisabledLabelModule,
        DateboxRangeLabelModule,
    ],
})
export class DateboxsComponentsModule {}
