export const environment = {
    production: true,
    local: false,

    otkerpApiAuth: 'https://otkwebprodauthapi.azurewebsites.net/api', // API - AUTH (PROD)

    otkerpApiAuthWeb: 'https://otkwebprodauthweb.azurewebsites.net/api', // API - AUTH WEB (PROD)

    otkerpApiGeneral: 'https://otkwebprodgeneralapi.azurewebsites.net/api', // API - GENERAL (PROD)

    otkerpApiPurchase: 'https://otkwebprodpurchaseapi.azurewebsites.net/api', // API - PURCHASE (PROD)

    otkerpApiStock: 'https://otkwebprodstockapi.azurewebsites.net/api', // API - STOCK (PROD)

    otkerpApiSales: 'https://otkwebprodsalesapi.azurewebsites.net/api', // API - SALES (PROD)

    otkerpApiFinancial: 'https://otkwebprodfinancialapi.azurewebsites.net/api', // API - FINANCIAL (PROD)

    otkerpApiSystemApplication:
        'https://otkwebprodsystemapplicationapi.azurewebsites.net/api', // API - SYSTEM APPLICATION (PROD)

    otkerpApiReports: 'https://otkwebprodreportsapi.azurewebsites.net/api', // API - REPORTS (PROD)
};
