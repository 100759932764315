import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'otk-cmp-radiogroup-label',
    templateUrl: './cmp-radiogroup-label.component.html',
    styleUrls: ['./cmp-radiogroup-label.component.scss'],
})
export class CmpRadiogroupLabelComponent implements OnInit {
    @Input() label: string = null;
    @Input() control: FormControl = new FormControl(null, []);
    @Input() width: string = '100%';

    @Input() validationMessageMode: string = 'always';
    @Input() errorRequired: string = `Deve ser informado.`;
    @Input() errorInvalid: string = `Valor inválido.`;
    @Input() maskInvalidMessage: string = `Formato inválido.`;

    @Input() dataSource = [
        { display: 'Sim', value: true },
        { display: 'Não', value: false },
    ];

    constructor() {}

    ngOnInit() {}

    ngOnDestroy() {}

    ngOnChanges() {}

    isValid(): boolean {
        if (!this.control) {
            return false;
        }

        if (this.control.pristine && this.control.untouched) {
            return true;
        }

        return !this.control.invalid;
    }

    validationError(): any {
        if (!this.control) {
            return { message: null };
        }

        if (!this.control.errors) {
            return { message: null };
        } else {
            // console.log("CONTROL ERROR ->", this.control.errors, this.control.valid);
            if (this.control.errors.required) {
                return { message: this.errorRequired };
            } else if (this.control.errors.minlength) {
                return {
                    message: `Deve possuir no mínimo ${this.control.errors.minlength.requiredLength} caracteres.`,
                };
            } else if (this.control.errors.maxlength) {
                return {
                    message: `Deve possuir no máximo ${this.control.errors.maxlength.requiredLength} caracteres.`,
                };
            } else if (this.control.errors.invalidMask) {
                return { message: this.maskInvalidMessage };
            } else {
                return {
                    message: this.errorInvalid
                        ? this.errorInvalid
                        : `Inválido.`,
                };
            }
        }
    }
}
