import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'otk-cmp-tagbox-disabled-label',
    templateUrl: './cmp-tagbox-disabled-label.component.html',
    styleUrls: ['./cmp-tagbox-disabled-label.component.scss'],
})
export class CmpTagboxDisabledLabelComponent implements OnInit {
    @Input() label: string = '';
    @Input() value: any[] = [];
    @Input() placeholder: string = '';
    @Input() width: string = '100%';
    @Input() maxDisplayedTags: number = null;

    constructor() {}

    ngOnInit() {}

    ngOnDestroy() {}
}
