import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

import { StateModeEnum } from '../../../../app.enums';
import { AppHelper } from '../../../../app.helper';

@Component({
    selector: 'otk-cmp-search-button',
    templateUrl: './cmp-search-button.component.html',
    styleUrls: ['./cmp-search-button.component.scss'],
})
export class CmpSearchButtonComponent implements OnInit, OnDestroy {
    private _stateSubscription: Subscription;
    enabledState: boolean = false;

    @Input() color: string = 'primary';
    @Input() tooltip: string = '';
    @Input() enabled: boolean = true;
    @Input() state$: BehaviorSubject<StateModeEnum> =
        new BehaviorSubject<StateModeEnum>(StateModeEnum.browse);

    constructor() {}

    ngOnInit() {
        this._stateSubscription = this.state$.subscribe((r) => {
            this.enabledState = AppHelper.isBrowseSelectedMode(r);
        });
    }

    ngOnDestroy(): void {
        this._stateSubscription.unsubscribe();
    }
}
