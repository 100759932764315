import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'otk-cmp-raised-button',
    templateUrl: './cmp-raised-button.component.html',
    styleUrls: ['./cmp-raised-button.component.scss'],
})
export class CmpRaisedButtonComponent implements OnInit {
    @Input() enabled: boolean = true;
    @Input() tooltip: string = '';
    @Input() color: string = null;
    @Output() onClick = new EventEmitter<any>();

    constructor() {}

    ngOnInit() {}

    actionBtnClick(e): void {
        this.onClick.emit(e);
    }
}
