import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import { hasRequiredField } from '../../../../app.functions';
import { AppHelper } from '../../../../app.helper';
import { MessageService } from '../../../../shared-services/message/message.service';

@Component({
    selector: 'otk-cmp-numberbox-label',
    templateUrl: './cmp-numberbox-label.component.html',
    styleUrls: ['./cmp-numberbox-label.component.scss'],
})
export class CmpNumberboxLabelComponent implements OnInit {
    @Input() label: string = null;
    @Input() format: string = '#,##0.00';
    @Input() help: string = null;
    @Input() copyButton: boolean = false;
    @Input() control: FormControl = new FormControl('', []);
    @Input() placeholder: string = '';
    @Input() width: string = '100%';
    @Input() widthHelp: string = '300px';
    @Input() showClearButton: boolean = false;
    @Input() showCurrency: boolean = false;
    @Input() showSpinButtons: boolean = false;
    @Input() min: number = null;
    @Input() max: number = null;
    @Input() readOnly: boolean = false;
    @Input() validationMessageMode: string = 'always';
    @Input() errorRequired: string = `Deve ser informado.`;
    @Input() errorGt: string = null;
    @Input() errorMin: string = null;
    @Input() errorMax: string = null;
    @Input() acceptNull: boolean = false;
    @Input() focus$: Subject<boolean> = new Subject<boolean>();

    @Output() onEnterKeyEvent = new EventEmitter<any>();

    @Output() onClearClick = new EventEmitter<any>();

    popoverVisible: boolean;

    constructor(private _message: MessageService) {}

    ngOnInit() {}

    ngOnDestroy() {}

    get _caption(): string {
        return this.label.replace(/\*/g, ' ');
    }

    get _captionLabel(): string {
        if (this.control && hasRequiredField(this.control)) {
            let t = this.label.replace(/\*/g, ' ');
            return `${t} *`;
        }
        return this.label;
    }

    togglePopover() {
        this.popoverVisible = !this.popoverVisible;
    }

    actionOnClearClick(e: any) {
        this.onClearClick.next(e);
    }

    actionOnEnterKey(e: any) {
        // console.log('a', e);
        // console.log('->', this.control.value);
        this.onEnterKeyEvent.emit(this.control.value);
    }

    actionOnCopyButtonClick() {
        if (!this.control.value) {
            return;
        }
        // console.log('actionOnCopyButtonClick', this.control.value);

        this.copyButton = false;

        AppHelper.copyToClipboard(this.control.value);

        this._message
            .simpleMessage('Conteúdo copiado.', 500)
            .pipe(take(1))
            .subscribe(() => {
                this.copyButton = true;
            });
    }

    get showCopyButton(): boolean {
        if (!this.control || !this.control.value) {
            return false;
        }

        if (this.control.disabled) {
            return true;
        }

        return false;
    }
}
