import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material';
import {
    DxPopoverModule,
    DxTemplateModule,
    DxTooltipModule,
} from 'devextreme-angular';

import { TextboxModule } from './../textbox/textbox.module';
import { CmpTextboxLabelCenterComponent } from './cmp-textbox-label-center.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        DxPopoverModule,
        DxTemplateModule,
        MatTooltipModule,

        TextboxModule,
    ],
    declarations: [CmpTextboxLabelCenterComponent],
    exports: [CmpTextboxLabelCenterComponent],
})
export class TextboxLabelCenterModule {}
