import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    MatIconModule,
    MatInputModule,
    MatTooltipModule,
} from '@angular/material';

import { KernelModule } from './../../../../kernel/kernel.module';
import { InputNumberEditComponent } from './input-number-edit.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule.withConfig({
            warnOnNgModelWithFormControl: 'never',
        }),
        FlexLayoutModule,
        MatIconModule,
        MatInputModule,
        MatTooltipModule,

        KernelModule,
    ],
    exports: [InputNumberEditComponent],
    declarations: [InputNumberEditComponent],
})
export class InputNumberEditModule {}
