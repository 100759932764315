import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
    DxPopoverModule,
    DxTextBoxModule,
    DxValidationGroupModule,
    DxValidatorModule,
} from 'devextreme-angular';

import { CmpTextboxEmailComponent } from './cmp-textbox-email.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        DxTextBoxModule,
        DxValidatorModule,
        DxValidationGroupModule,
        DxPopoverModule,
    ],
    declarations: [CmpTextboxEmailComponent],
    exports: [CmpTextboxEmailComponent],
})
export class TextboxEmailModule {}
