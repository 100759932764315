import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'otk-cmp-buttons-area',
    templateUrl: './cmp-buttons-area.component.html',
    styleUrls: ['./cmp-buttons-area.component.scss'],
})
export class CmpButtonsAreaComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
