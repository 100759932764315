import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    Renderer2,
    ViewChild,
} from '@angular/core';

@Component({
    selector: 'otk-input-number-edit',
    templateUrl: './input-number-edit.component.html',
    styleUrls: ['./input-number-edit.component.scss'],
})
export class InputNumberEditComponent implements OnInit {
    @Input() value: number = 0;
    @Input() readonly: boolean = false;
    @Input() tooltip: string = 'Clique para editar...';
    @Input() decimals: number;
    @Input() canNull: boolean = false;
    @Output() changeValue: EventEmitter<number> = new EventEmitter();
    @Output() editingMode: EventEmitter<boolean> = new EventEmitter();

    @ViewChild('cmp') cmp: ElementRef;

    _value: number = 0;
    editMode: boolean = false;
    maskDecimal = {};

    constructor(private _renderer: Renderer2) {}

    ngOnInit() {
        this._value = Number(this.value);

        if (this.readonly) {
            this.tooltip = '';
        }
    }

    ngOnChanges() {
        this.maskDecimal = {};
        if (this.decimals) {
            this.maskDecimal = { digitos: this.decimals };
        }
        if (this.canNull) {
            this.maskDecimal = Object.assign(this.maskDecimal, {
                canNull: true,
            });
        }
    }

    inputClick() {
        if (this.readonly) {
            return;
        }
        this.editMode = true;
        this.editingMode.emit(this.editMode);
    }

    inputBlur() {
        this.editingMode.emit(false);
        setTimeout(() => {
            if (this.editMode) {
                if (this._value != this.value) {
                    this.actionBtnConfirm();
                } else {
                    this.actionBtnCancel();
                }
            }
        }, 200);
    }

    inputKeyup(event: any) {
        if (event && event.keyCode == 27) {
            if (Number.isNaN(this._value)) {
                this.value = null;
                this.editMode = false;
                this._renderer.setProperty(this.cmp.nativeElement, 'text', '');
                this._renderer.setProperty(this.cmp.nativeElement, 'value', '');
                return;
            }
            this.actionBtnCancel();
        }
    }

    actionBtnConfirm() {
        if (this.value < 0) {
            this.value = 0;
        }
        this._value = this.value;
        this.editMode = false;
        this.changeValue.emit(this.value);
    }

    actionBtnCancel() {
        this.value = Number(this._value);
        this.editMode = false;
    }
}
