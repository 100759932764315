import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DxTextBoxModule } from 'devextreme-angular';

import { CmpLoginTextboxLabelComponent } from './cmp-login-textbox-label.component';

@NgModule({
    imports: [CommonModule, ReactiveFormsModule, DxTextBoxModule],
    declarations: [CmpLoginTextboxLabelComponent],
    exports: [CmpLoginTextboxLabelComponent],
})
export class LoginTextboxLabelModule {}
