import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
    selector: 'app-message-dialog',
    template: `
        <div class="message-dialog">
            <span mat-dialog-title class="title">
                {{ title }}
            </span>

            <mat-dialog-content
                class="message"
                fxLayout="row"
                fxLayoutAlign="start center"
            >
                <span [innerHtml]="message"> </span>
            </mat-dialog-content>

            <mat-dialog-actions style="margin-bottom: 0px;padding-bottom: 5px;">
                <div fxLayout="row" fxLayoutAlign="center" fxFlex>
                    <button
                        mat-raised-button
                        color="primary"
                        (click)="dialogRef.close(true)"
                    >
                        OK
                    </button>
                </div>
            </mat-dialog-actions>
        </div>
    `,

    // mat-button
    // mat-raised-button color="primary"
    // mat-raised-button color="accent"

    styles: [
        `
            .message-dialog {
            }
            .title {
                padding-top: 10px;
                margin-bottom: 20px;
                font-family: 'Open Sans', sans-serif;
                font-size: 18px;
                font-weight: 500;
            }
            .message {
                min-height: 96px;
                padding-bottom: 8px;
                font-family: 'Open Sans', sans-serif;
                font-size: 14px;
            }
        `,
    ],
})
export class MessageDialogComponent implements OnInit {
    public title: string;
    public message: string;

    constructor(public dialogRef: MatDialogRef<MessageDialogComponent>) {}

    ngOnInit() {}
}
