import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DxNumberBoxModule } from 'devextreme-angular';

import { CmpNumberboxComponent } from './cmp-numberbox.component';

@NgModule({
    imports: [CommonModule, ReactiveFormsModule, DxNumberBoxModule],
    declarations: [CmpNumberboxComponent],
    exports: [CmpNumberboxComponent],
})
export class NumberboxModule {}
