import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'otk-cmp-edit-button',
    templateUrl: './cmp-edit-button.component.html',
    styleUrls: ['./cmp-edit-button.component.scss'],
})
export class CmpEditButtonComponent implements OnInit {
    @Input() tooltip: string = 'Editar';
    @Input() enabled: boolean = true;
    @Input() styleClass: string = null;

    constructor() {}

    ngOnInit() {}
}
