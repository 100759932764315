import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';

import { StateModeEnum } from '../../../../app.enums';

@Component({
    selector: 'otk-cmp-save-cancel-button-datagrid',
    templateUrl: './cmp-save-cancel-button-datagrid.component.html',
    styleUrls: ['./cmp-save-cancel-button-datagrid.component.scss'],
})
export class CmpSaveCancelButtonDatagridComponent implements OnInit, OnDestroy {
    visible: boolean = true;
    enabled: boolean = false;
    isInsert: boolean = false;

    private stateSubscription: Subscription;
    private validSubscription: Subscription;

    @Input() captionAdd: string = 'Adicionar';
    @Input() captionEdit: string = 'Salvar';
    @Input() state$: BehaviorSubject<StateModeEnum> =
        new BehaviorSubject<StateModeEnum>(StateModeEnum.undefined);
    @Input() valid$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );
    @Output() saveClick: EventEmitter<any> = new EventEmitter();
    @Output() cancelClick: EventEmitter<any> = new EventEmitter();

    constructor() {}

    ngOnInit() {
        this.stateSubscription = this.state$.subscribe((r) => {
            this.visible = r == StateModeEnum.insert || r == StateModeEnum.edit;
            this.isInsert = r == StateModeEnum.insert;
        });

        this.validSubscription = this.valid$.subscribe((r) => {
            this.enabled = r;
        });
    }

    ngOnDestroy() {
        this.stateSubscription.unsubscribe();
        this.validSubscription.unsubscribe();
    }

    actionButtonSaveClick($event): void {
        this.saveClick.emit($event);
    }

    actionButtonCancelClick($event): void {
        this.cancelClick.emit($event);
    }
}
