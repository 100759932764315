import 'devextreme/dist/js/localization/dx.messages.pt';

import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { locale } from 'devextreme/localization';

import { AppProcessingInterceptor } from './app-processing.interceptor';
import { AppTokenInterceptor } from './app-token.interceptor';
import { AppComponent } from './app.component';
import { RoutingModule } from './app.routing.module';
import { AppService } from './app.service';
import { CoreModule } from './core/core.module';
import { AppStoreModule } from './store/app-store.module';

registerLocaleData(localePt);
locale('pt');

@NgModule({
    imports: [
        BrowserModule,
        HttpModule,
        HttpClientModule,
        BrowserAnimationsModule,

        CoreModule,
        RoutingModule,

        AppStoreModule,
    ],
    declarations: [AppComponent],
    providers: [
        AppService,
        {
            provide: LOCALE_ID,
            useValue: 'pt-BR', //'en-US'
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AppTokenInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AppProcessingInterceptor,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
