import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'otk-cmp-five-star-button',
    templateUrl: './cmp-five-star-button.component.html',
    styleUrls: ['./cmp-five-star-button.component.scss'],
})
export class CmpFiveStarButtonComponent implements OnInit {
    @Input() caption: string = '';
    @Input() disabled: boolean = false;
    @Input('initialValue') value: number = 0;
    @Output() changeValue = new EventEmitter<number>();

    constructor() {}

    ngOnInit() {
        this._adjustValue();
    }

    private _adjustValue(): void {
        if (!this.value || this.value < 1 || this.value > 5) {
            this.value = 1;
        }
    }

    actionBtnClick(n: number): void {
        if (this.value == n) {
            this.value = n - 1;
        } else {
            this.value = n;
        }
        this._adjustValue();
        this.changeValue.emit(this.value);
        event.stopPropagation();
    }
}
