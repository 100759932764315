import {
    Directive,
    ElementRef,
    HostListener,
    Input,
    OnInit,
} from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[otk-mask-lower]',
})
export class MaskLowerDirective implements OnInit {
    @Input('otk-mask-lower') lower: boolean = false;

    constructor(private el: ElementRef, private control: NgControl) {}

    ngOnInit() {}

    @HostListener('input', ['$event']) onEvent($event) {
        if (!this.lower) return;

        let lower = this.el.nativeElement.value.toLowerCase();
        this.control.control.setValue(lower);
    }
}
