import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DxTextAreaModule } from 'devextreme-angular';

import { CmpTextareaComponent } from './cmp-textarea.component';

@NgModule({
    imports: [ReactiveFormsModule, DxTextAreaModule],
    declarations: [CmpTextareaComponent],
    exports: [CmpTextareaComponent],
})
export class TextareaModule {}
