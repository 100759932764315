import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'otk-cmp-checkbox-material',
    templateUrl: './cmp-checkbox-material.component.html',
    styleUrls: ['./cmp-checkbox-material.component.scss'],
})
export class CmpCheckboxMaterialComponent implements OnInit {
    @Input() checked: boolean = false;
    @Input() enabled: boolean = true;
    @Output() change = new EventEmitter<any>();

    constructor() {}

    ngOnInit() {}

    ngOnChanges() {
        this.actionChange(this.checked);
    }

    actionChange(e: boolean) {
        this.change.emit(e == true);
    }
}
