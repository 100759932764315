import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'otk-cmp-switch-label',
    templateUrl: './cmp-switch-label.component.html',
    styleUrls: ['./cmp-switch-label.component.scss'],
})
export class CmpSwitchLabelComponent implements OnInit {
    @Input() label: string = null;
    @Input() control: FormControl = new FormControl(null, []);
    @Input() help: string = null;
    @Input() placeholder: string = '';
    @Input() noMarginBottom: boolean = false;
    @Input() fontNormal: boolean = true;

    @Input() width: string = '100%';
    @Input() widthHelp: string = '300px';

    popoverVisible: boolean;

    constructor() {}

    ngOnInit() {}

    get _caption(): string {
        return this.label.replace(/\*/g, ' ');
    }

    get _captionLabel(): string {
        return this.label;
    }

    togglePopover() {
        this.popoverVisible = !this.popoverVisible;
    }
}
