import { NgModule } from '@angular/core';

import { DataSharedService } from './services/data-shared.service';
import { DataSharedServicesStoreModule } from './store/data-shared-services.store.module';

@NgModule({
    imports: [DataSharedServicesStoreModule],
    providers: [DataSharedService],
})
export class DataSharedServicesModule {}
