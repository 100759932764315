import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AuthModule } from '../auth/auth.module';
import { SharedServicesModule } from '../shared-services/shared-services.module';

@NgModule({
    imports: [CommonModule, AuthModule, SharedServicesModule],
})
export class CoreModule {}
