import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { dataSharedServicesReducer } from './data-shared-services.reducer';
import { DATA_SHARED_SERVICES_STORE } from './data-shared-services.state';
import { DataSharedServicesStoreService } from './data-shared-services.store.service';

@NgModule({
    imports: [
        StoreModule.forFeature(
            DATA_SHARED_SERVICES_STORE,
            dataSharedServicesReducer
        ),
    ],
    exports: [StoreModule],
    providers: [DataSharedServicesStoreService],
})
export class DataSharedServicesStoreModule {}
