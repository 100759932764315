import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule,
} from '@angular/material';

import { CmpAddButtonComponent } from './add-button/cmp-add-button.component';
import { CmpBackButtonComponent } from './back-button/cmp-back-button.component';
import { CmpBackButtonOrdemDeCompraComponent } from './back-button-ordem-de-compra/cmp-back-button-ordem-de-compra.component';
import { CmpCloseButtonComponent } from './close-button/cmp-close-button.component';
import { CmpEditButtonComponent } from './edit-button/cmp-edit-button.component';
import { CmpEmailButtonComponent } from './email-button/cmp-email-button.component';
import { CmpExpandCompressButtonComponent } from './expand-compress-button/cmp-expand-compress-button.component';
import { CmpFiveStarButtonComponent } from './five-star-button/cmp-five-star-button.component';
import { CmpHelpButton } from './help-button/cmp-help-button.component';
import { CmpIconButtonComponent } from './icon-button/cmp-icon-button.component';
import { CmpLoginRaisedPrimaryButtonComponent } from './login-raised-primary-button/cmp-login-raised-primary-button.component';
import { CmpNewButtonComponent } from './new-button/cmp-new-button.component';
import { CmpPdfButtonComponent } from './pdf-button/cmp-pdf-button.component';
import { CmpPrintButtonComponent } from './print-button/cmp-print-button.component';
import { CmpProcessButtonComponent } from './process-button/cmp-process-button.component';
import { CmpProcessPrimaryButtonComponent } from './process-primary-button/cmp-process-primary-button.component';
import { CmpProcessWarnButtonComponent } from './process-warn-button/cmp-process-warn-button.component';
import { CmpRaisedAccentButtonComponent } from './raised-accent-button/cmp-raised-accent-button.component';
import { CmpRaisedButtonComponent } from './raised-button/cmp-raised-button.component';
import { CmpRaisedPrimaryButtonComponent } from './raised-primary-button/cmp-raised-primary-button.component';
import { CmpRaisedWarnButtonComponent } from './raised-warn-button/cmp-raised-warn-button.component';
import { CmpRefreshButton } from './refresh-button/cmp-refresh-button.component';
import { CmpRemoveButtonComponent } from './remove-button/cmp-remove-button.component';
import { CmpSaveCancelButtonDatagridComponent } from './save-cancel-button-datagrid/cmp-save-cancel-button-datagrid.component';
import { CmpSaveCancelButtonComponent } from './save-cancel-button/cmp-save-cancel-button.component';
import { ButtonComponentSearchModule } from './search-button/button-component-search.module';
import { CmpTimelineButtonComponent } from './timeline-button/cmp-timeline-button.component';
import { CmpToolsButton } from './tools-button/cmp-tools-button.component';
import { CmpWizardButtonComponent } from './wizard-button/cmp-wizard-button.component';
import { CmpLogButton } from './log-button/cmp-log-button.component';

@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        MatMenuModule,

        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
    ],
    exports: [
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        ButtonComponentSearchModule,

        CmpAddButtonComponent,
        CmpBackButtonComponent,
        CmpBackButtonOrdemDeCompraComponent,
        CmpCloseButtonComponent,
        CmpEmailButtonComponent,
        CmpFiveStarButtonComponent,
        CmpIconButtonComponent,
        CmpEditButtonComponent,
        CmpNewButtonComponent,
        CmpPdfButtonComponent,
        CmpProcessButtonComponent,
        CmpProcessPrimaryButtonComponent,
        CmpProcessWarnButtonComponent,
        CmpRaisedAccentButtonComponent,
        CmpRaisedButtonComponent,
        CmpRaisedPrimaryButtonComponent,
        CmpLoginRaisedPrimaryButtonComponent,
        CmpRaisedWarnButtonComponent,
        CmpRefreshButton,
        CmpHelpButton,
        CmpRemoveButtonComponent,
        CmpSaveCancelButtonComponent,
        CmpSaveCancelButtonDatagridComponent,
        CmpToolsButton,
        CmpWizardButtonComponent,
        CmpLogButton,
    ],
    declarations: [
        CmpAddButtonComponent,
        CmpBackButtonComponent,
        CmpBackButtonOrdemDeCompraComponent,
        CmpCloseButtonComponent,
        CmpEditButtonComponent,
        CmpEmailButtonComponent,
        CmpExpandCompressButtonComponent,
        CmpNewButtonComponent,
        CmpFiveStarButtonComponent,
        CmpIconButtonComponent,
        CmpPdfButtonComponent,
        CmpPrintButtonComponent,
        CmpProcessButtonComponent,
        CmpProcessPrimaryButtonComponent,
        CmpProcessWarnButtonComponent,
        CmpRefreshButton,
        CmpHelpButton,
        CmpRaisedAccentButtonComponent,
        CmpRaisedButtonComponent,
        CmpRaisedPrimaryButtonComponent,
        CmpLoginRaisedPrimaryButtonComponent,
        CmpRaisedWarnButtonComponent,
        CmpRemoveButtonComponent,
        CmpSaveCancelButtonComponent,
        CmpSaveCancelButtonDatagridComponent,
        CmpTimelineButtonComponent,
        CmpToolsButton,
        CmpWizardButtonComponent,
        CmpLogButton,
    ],
})
export class ButtonsComponentsModule {}
