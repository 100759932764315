import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'otk-cmp-back-button-ordem-de-compra',
    templateUrl: './cmp-back-button-ordem-de-compra.component.html',
    styleUrls: ['./cmp-back-button-ordem-de-compra.component.scss'],
})
export class CmpBackButtonOrdemDeCompraComponent implements OnInit {
    @Input() visible: boolean = true;

    constructor(private router: Router) {}

    ngOnInit() {}

    goBack() {
        this.router.navigate(['/compras/ordem-de-compra/abertas']);
    }
}
