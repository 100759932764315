import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PermissoesSistema } from '../models/auth-user.model';
import { AuthService } from '../services/auth.service';

@Injectable()
export class SistemaAuthService {
    acessarExportacao$: Observable<boolean>;

    constructor(public authService: AuthService) {
        this.acessarExportacao$ = this.authService.permissoesSistema$.pipe(
            map((r: PermissoesSistema) => {
                return r.exportacao;
            })
        );
    }
}
