import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';

import { PermissoesCadastros } from '../models/auth-user.model';
import { AuthService } from '../services/auth.service';
import { CadastrosAuthService } from './cadastros-auth.service';

@Injectable({
    providedIn: 'root',
})
export class CadastrosAuthProdutoService extends CadastrosAuthService {
    inserirOuEditarProduto$: Observable<boolean>;

    constructor(public service: AuthService) {
        super(service);

        this.inserirOuEditarProduto$ = combineLatest(
            this.service.administrador$,
            this.service.permissoesCadastros$,
            (a, c: PermissoesCadastros) => a || c.produtos
        );
    }
}
