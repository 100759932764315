import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
    selector: '[scroller]'
})
export class ScrollerDirective {


    constructor() {
     }

    // <summary>
    // Event ouptut the current scroll percentage
    // </summary>
    @Output() onScroll = new EventEmitter<any>();

    // <summary>
    // Holds the current percent value
    // </summary>
    percentValue: number = 0;

    // <summary>
    // Event listener for scroll event on the specific ui element
    // </summary>
    @HostListener("scroll", ["$event"]) onListenerTriggered(event: UIEvent): void {

        // Calculate the scroll percentage
        const percent = Math.round((event.srcElement.scrollTop / (event.srcElement.scrollHeight - event.srcElement.clientHeight)) * 100);

        // Compare the new with old and only raise the event if values change
        if (this.percentValue !== percent) {

            // Update the percent value
            this.percentValue = percent;

            // Emit the event
            let r = {
                percent: percent,
                scrollTop: event.srcElement.scrollTop,
                scrollHeight: event.srcElement.scrollHeight,
                clientHeight: event.srcElement.clientHeight,
            }
            this.onScroll.emit(r);
        }
    }

}
