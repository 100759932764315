import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'otk-cmp-checkbox-filter',
    templateUrl: './cmp-checkbox-filter.component.html',
    styleUrls: ['./cmp-checkbox-filter.component.scss'],
})
export class CmpCheckboxFilterComponent implements OnInit {
    @Input() tooltip: string = '';
    @Input() control: FormControl = new FormControl(null, []);

    constructor() {}

    ngOnInit() {}
}
