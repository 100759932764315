import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'otk-cmp-login-checkbox-label',
    templateUrl: './cmp-login-checkbox-label.component.html',
    styleUrls: ['./cmp-login-checkbox-label.component.scss'],
})
export class CmpLoginCheckboxLabelComponent implements OnInit {
    @Input() label: string = null;
    @Input() control: FormControl = new FormControl(null, []);
    @Input() help: string = null;
    @Input() placeholder: string = '';

    @Input() width: string = '100%';
    @Input() widthHelp: string = '300px';
    @Input() noMarginBottom: boolean = false;

    popoverVisible: boolean;

    constructor() {}

    ngOnInit() {}

    get _caption(): string {
        return this.label.replace(/\*/g, ' ');
    }

    get _captionLabel(): string {
        return this.label;
    }

    togglePopover() {
        this.popoverVisible = !this.popoverVisible;
    }
}
