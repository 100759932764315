import {
    Directive,
    ElementRef,
    HostListener,
    OnInit,
    Renderer,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
    selector: 'input[otk-mask-phone]',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: MaskPhoneDirective,
            multi: true,
        },
    ],
})
export class MaskPhoneDirective implements ControlValueAccessor, OnInit {
    private inputElement: HTMLInputElement;

    onTouched: any;
    onChange: any;

    constructor(private renderer: Renderer, private elementRef: ElementRef) {}

    ngOnInit() {}

    writeValue(value: any): void {
        if (!this.inputElement) {
            if (!this._setup()) {
                console.error('Error on Setup Input Phone Mask.');
                return;
            }
        }

        if (!value && value == null) {
            return;
        }

        let r = this._phoneFormat(value);
        this.renderer.setElementProperty(this.inputElement, 'value', r);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    private _getOnlyNumbers(e) {
        let r = e.replace(/[^0-9]+/g, '');
        return r;
    }

    private _phoneFormat(v): string {
        var r = this._getOnlyNumbers(v);
        if (!r) {
            return r;
        }

        if (r && r.length == 10) {
            r =
                '(' +
                r.substr(0, 2) +
                ') ' +
                r.substr(2, 4) +
                '-' +
                r.substr(6);
            return r;
        }

        if (r && r.length >= 11) {
            r =
                '(' +
                r.substr(0, 2) +
                ') ' +
                r.substr(2, 5) +
                '-' +
                r.substr(7, 4);
            return r;
        }

        if (r && r.length >= 2) {
            r = '(' + r.substr(0, 2) + ') ' + r.substr(2);
            return r;
        }

        return r;
    }

    private _isSpecialKey(e): boolean {
        var charCode = e.which ? e.which : e.keyCode;
        return (
            charCode == 8 || // Tab
            charCode == 9 || //
            charCode == 13 || // Enter
            charCode == 16 || // Shift
            charCode == 27 || // Esc
            charCode == 35 || // Esc
            charCode == 36 || // Home
            charCode == 37 || // Esquerda
            charCode == 39 || // Direita
            charCode == 144
        ); // Numlock
    }

    private _setup(): boolean {
        if (this.elementRef.nativeElement.tagName === 'INPUT') {
            this.inputElement = this.elementRef.nativeElement;
            return true;
        }
        return false;
    }

    @HostListener('keyup', ['$event']) onKeyup($event: any) {
        if (this._isSpecialKey($event)) {
            return;
        }

        let r = this._phoneFormat($event.target.value);

        this.onChange(r);
        $event.target.value = r;
    }

    @HostListener('blur', ['$event']) onBlur($event: any) {
        var v = $event.target.value;

        let r = this._phoneFormat($event.target.value);

        this.onChange(r);
        $event.target.value = r;
    }
}
