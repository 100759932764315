import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material';
import {
    DxPopoverModule,
    DxSwitchModule,
    DxTemplateModule,
} from 'devextreme-angular';
import { CmpSwitchLabelComponent } from './cmp-switch-label.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        DxPopoverModule,
        DxTemplateModule,
        MatTooltipModule,
        DxSwitchModule,
        FlexLayoutModule,
    ],
    declarations: [CmpSwitchLabelComponent],
    exports: [CmpSwitchLabelComponent],
})
export class SwitchLabelModule {}
