import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule, MatTooltipModule } from '@angular/material';

import { DevextremeComponentsModule } from '../../../devextreme-components.module';
import { CmpDatagridAreaComponent } from './cmp-datagrid-area.component';
import { CmpDatagridColumnChooserButtonComponent } from './cmp-datagrid-column-chooser-button.component';
import { CmpDatagridFabToolbarComponent } from './cmp-datagrid-fab-toolbar.component';

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        MatTooltipModule,
        DevextremeComponentsModule,
    ],
    exports: [
        CmpDatagridAreaComponent,
        CmpDatagridFabToolbarComponent,
        CmpDatagridColumnChooserButtonComponent,
    ],
    declarations: [
        CmpDatagridAreaComponent,
        CmpDatagridFabToolbarComponent,
        CmpDatagridColumnChooserButtonComponent,
    ],
})
export class DatagridComponentsModule {}
