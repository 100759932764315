import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material';
import { DxPopoverModule, DxTemplateModule } from 'devextreme-angular';

import { NumberboxModule } from '../numberbox/numberbox.module';
import { CmpNumberboxLabelComponent } from './cmp-numberbox-label.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        DxPopoverModule,
        DxTemplateModule,
        MatTooltipModule,

        NumberboxModule,
    ],
    declarations: [CmpNumberboxLabelComponent],
    exports: [CmpNumberboxLabelComponent],
})
export class NumberboxLabelModule {}
