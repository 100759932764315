import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'otk-cmp-div-area',
    templateUrl: './cmp-div-area.component.html',
    styleUrls: ['./cmp-div-area.component.scss'],
})
export class CmpDivAreaComponent implements OnInit {
    @Input() caption: string = null;
    @Input() space: boolean = true;
    @Input() help: string = null;
    @Input() wizardTooltip: string = '';
    @Input() wizardButtonVisible: boolean = false;
    @Input() editTooltip: string = '';
    @Input() editButtonVisible: boolean = false;
    @Output() wizardClick = new EventEmitter<any>();
    @Output() editClick = new EventEmitter<any>();
    @Input() widthHelp: string = '300px';
    popoverVisible: boolean;

    constructor() {}

    ngOnInit() {}

    actionBtnWizard() {
        this.wizardClick.emit();
    }

    actionBtnEdit() {
        this.editClick.emit();
    }

    togglePopover() {
        this.popoverVisible = !this.popoverVisible;
    }
}
