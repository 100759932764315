import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'otk-cmp-content-area',
    templateUrl: './cmp-content-area.component.html',
    styleUrls: ['./cmp-content-area.component.scss'],
})
export class CmpContentAreaComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
