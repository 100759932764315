import { Component, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
    selector: 'otk-cmp-title-content-area-ordem-de-compra',
    templateUrl: './cmp-title-content-area-ordem-de-compra.component.html',
    styleUrls: ['./cmp-title-content-area-ordem-de-compra.component.scss'],
})
export class CmpTitleContentAreaOrdemDeCompraComponent
    implements OnInit, OnDestroy
{
    @Input() title: string = 'XXXX';
    @Input() subTitle: string = '';

    constructor() {}

    ngOnInit() {}

    ngOnDestroy(): void {}

    ngOnChanges(): void {}
}
