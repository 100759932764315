import {
    Directive,
    ElementRef,
    HostListener,
    Input,
    OnInit,
} from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[otk-mask-upper]',
})
export class MaskUpperDirective implements OnInit {
    @Input('otk-mask-upper') upper: boolean = false;

    constructor(private el: ElementRef, private control: NgControl) {}

    ngOnInit() {}

    @HostListener('input', ['$event']) onEvent($event) {
        if (!this.upper) return;

        let upper = this.el.nativeElement.value.toUpperCase();
        this.control.control.setValue(upper);
    }
}
