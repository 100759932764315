import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'otk-cmp-wizard-button',
    templateUrl: './cmp-wizard-button.component.html',
    styleUrls: ['./cmp-wizard-button.component.scss'],
})
export class CmpWizardButtonComponent implements OnInit {
    @Input() tooltip: string = '';
    @Input() enabled: boolean = true;
    @Input() styleClass: string = null;

    constructor() {}

    ngOnInit() {}
}
