import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'otk-cmp-content-area-tab',
    templateUrl: './cmp-content-area-tab.component.html',
    styleUrls: ['./cmp-content-area-tab.component.scss'],
})
export class CmpContentAreaTabComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
