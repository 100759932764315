import {
    Directive,
    ElementRef,
    HostListener,
    Input,
    OnInit,
    Renderer,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
    selector: 'input[otk-mask-decimal]',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: MaskDecimalDirective,
            multi: true,
        },
    ],
})
export class MaskDecimalDirective implements ControlValueAccessor, OnInit {
    private inputElement: HTMLInputElement;

    onTouched: any;
    onChange: any;

    // private _decimalSeparator: string;
    // private _thousandSeparator: string;
    private _decimalNumbers: number = 2;
    private _canEmpty: boolean = false;
    private _canNull: boolean = false;

    @Input('otk-mask-decimal') mask: any;

    constructor(private renderer: Renderer, private elementRef: ElementRef) {}

    ngOnInit() {
        // this._decimalSeparator = this.mask.decimal || ',';
        // this._thousandSeparator = this.mask.milhar || '.';
        this._decimalNumbers = this.mask.digitos || 2;

        if (this.mask && this.mask.digitos == 0) {
            this._decimalNumbers = 0;
            this._canEmpty = true;
        }

        if (this.mask && this.mask.digitos == 'auto') {
            this._decimalNumbers = -1;
            this._canEmpty = true;
        }

        if (this.mask && this.mask.canNull) {
            this._canNull = true;
        }
    }

    writeValue(value: any): void {
        if (!this.inputElement) {
            if (!this._setup()) {
                console.error('Error on Setup Input Element.');
                return;
            }
        }

        if (!value && value != 0) {
            return;
        }

        this.renderer.setElementProperty(
            this.inputElement,
            'value',
            this._numberFormat(value)
        );
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    private _getOnlyNumbers(e) {
        let r = e.replace(/[^0-9,^,^-]+/g, '');
        return r;
    }

    private _isSpecialKey(e): boolean {
        // https://www.cambiaresearch.com/articles/15/javascript-char-codes-key-codes
        var charCode = e.which ? e.which : e.keyCode;
        return (
            charCode == 9 || // tab
            charCode == 13 || // Enter
            charCode == 16 || // Shift
            charCode == 27 || // Esc
            charCode == 35 || // End
            charCode == 36 || // Home
            charCode == 37 || // Esquera
            charCode == 39 || // Direita
            charCode == 144
        ); // Numlock
    }

    private _numberValue(v): string {
        let r;
        if (typeof v === 'string' || v instanceof String) {
            r = this._getOnlyNumbers(v);
        } else {
            r = String(v);
        }

        r = r.replace(',', '.');
        if (isNaN(r)) {
            r = 0;
        }

        if (r == '' && this._canNull) {
            return null;
        }

        if (r == 0 && this._canEmpty) {
            return '';
        }

        let n = r;
        if (this._decimalNumbers > 0) {
            n = Number(r).toFixed(this._decimalNumbers);
        }

        return n;
    }

    private _numberFormat(v): string {
        var r = this._numberValue(v);
        if (r == null) {
            return null;
        }

        if (this.mask.digitos == 'auto') {
            if (r == '') {
                return '';
            }
        }

        var parts = r.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        return parts.join(',');
    }

    private _setup(): boolean {
        if (this.elementRef.nativeElement.tagName === 'INPUT') {
            this.inputElement = this.elementRef.nativeElement;
            this.inputElement.style.textAlign = 'right';

            return true;
        }
        return false;
    }

    @HostListener('keyup', ['$event']) onKeyup($event: any) {
        if (this._isSpecialKey($event)) {
            return;
        }

        let v = this._getOnlyNumbers($event.target.value);

        this.onChange(this._numberValue(v));
        $event.target.value = v;
    }

    @HostListener('blur', ['$event']) onBlur($event: any) {
        var v = $event.target.value;

        this.onChange(this._numberValue(v));
        $event.target.value = this._numberFormat(v);
    }

    @HostListener('focus', ['$event']) onFocus($event: any) {
        $event.target.select();
    }
}
