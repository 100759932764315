import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'otk-cmp-back-button',
    templateUrl: './cmp-back-button.component.html',
    styleUrls: ['./cmp-back-button.component.scss'],
})
export class CmpBackButtonComponent implements OnInit {
    @Input() visible: boolean = true;

    constructor(private _location: Location) {}

    ngOnInit() {}

    goBack() {
        this._location.back();
    }
}
