import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'otk-cmp-login-raised-primary-button',
    templateUrl: './cmp-login-raised-primary-button.component.html',
    styleUrls: ['./cmp-login-raised-primary-button.component.scss'],
})
export class CmpLoginRaisedPrimaryButtonComponent implements OnInit {
    @Input() enabled: boolean = true;
    @Input() tooltip: string = '';
    @Input() styles: null;
    @Output() onClick = new EventEmitter<any>();

    constructor() {}

    ngOnInit() {}

    actionBtnClick(e): void {
        this.onClick.emit(e);
    }
}
