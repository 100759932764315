import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'otk-cmp-datagrid-column-chooser-button',
    templateUrl: './cmp-datagrid-column-chooser-button.component.html',
    styleUrls: ['./cmp-datagrid-column-chooser-button.component.scss'],
})
export class CmpDatagridColumnChooserButtonComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
