import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material';
import {
    DxNumberBoxModule,
    DxPopoverModule,
    DxTemplateModule,
} from 'devextreme-angular';
import { CmpNumberboxSpinnerLabelComponent } from './cmp-numberbox-spinner-label.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        DxNumberBoxModule,
        DxPopoverModule,
        DxTemplateModule,
        MatTooltipModule,
    ],
    declarations: [CmpNumberboxSpinnerLabelComponent],
    exports: [CmpNumberboxSpinnerLabelComponent],
})
export class NumberboxSpinnerLabelModule {}
