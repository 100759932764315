import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
    DxPopoverModule,
    DxTextBoxModule,
    DxValidationGroupModule,
    DxValidatorModule,
} from 'devextreme-angular';

import { CmpNewPasswordTextboxSenhaComponent } from './cmp-new-password-textbox-senha.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        DxTextBoxModule,
        DxValidatorModule,
        DxValidationGroupModule,
        DxPopoverModule,
    ],
    declarations: [CmpNewPasswordTextboxSenhaComponent],
    exports: [CmpNewPasswordTextboxSenhaComponent],
})
export class NewPasswordTextboxSenhaModule {}
