import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanLoad,
    Route,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AppRoutingService } from '../../app-routing.service';
import { AuthService } from '../services/auth.service';
@Injectable()
export class AdministradorGuard implements CanActivate, CanLoad {
    constructor(
        private _routingService: AppRoutingService,
        private _authService: AuthService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        return this._administrador();
    }

    canLoad(route: Route): Observable<boolean> {
        return this._administrador();
    }

    private _administrador(): Observable<boolean> {
        this._authService.recuperarLogin();

        return this._authService.usuarioAutenticado$.pipe(
            take(1),
            map((usr) => {
                if (usr && usr.administrador) {
                    return true;
                }

                this._routingService.sistemaAvisoAdministrador();
                return false;
            })
        );
    }
}
