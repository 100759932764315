import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DxTextBoxModule } from 'devextreme-angular';

import { CmpTextboxSearchComponent } from './cmp-textbox-search.component';

@NgModule({
    imports: [CommonModule, ReactiveFormsModule, DxTextBoxModule],
    declarations: [CmpTextboxSearchComponent],
    exports: [CmpTextboxSearchComponent],
})
export class TextboxSearchModule {}
