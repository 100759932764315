import { NgModule } from '@angular/core';

import { ClickOutsideDirective } from './directives/click-outside.directive';
import { EqualValidatorDirective } from './directives/equal-validator.directive';
import { FocusDirective } from './directives/focus.directive';
import { GravatarImageDirective } from './directives/gravatar-image.directive';
import { MaskDecimalDirective } from './directives/mask-decimal.directive';
import { MaskLowerDirective } from './directives/mask-lower.directive';
import { MaskPhoneDirective } from './directives/mask-phone.directive';
import { MaskUpperDirective } from './directives/mask-upper.directive';
import { ScrollerDirective } from './directives/scroller.directive';

@NgModule({
    imports: [],
    exports: [
        ClickOutsideDirective,
        EqualValidatorDirective,
        FocusDirective,
        GravatarImageDirective,
        MaskDecimalDirective,
        MaskPhoneDirective,
        MaskUpperDirective,
        MaskLowerDirective,
        ScrollerDirective,
    ],
    declarations: [
        ClickOutsideDirective,
        EqualValidatorDirective,
        FocusDirective,
        GravatarImageDirective,
        MaskDecimalDirective,
        MaskPhoneDirective,
        MaskUpperDirective,
        MaskLowerDirective,
        ScrollerDirective,
    ],
    entryComponents: [],
    providers: [],
})
export class KernelModule {}
