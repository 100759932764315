import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DxRadioGroupModule } from 'devextreme-angular';

import { CmpRadiogroupLabelComponent } from './cmp-radiogroup-label.component';

@NgModule({
    imports: [CommonModule, ReactiveFormsModule, DxRadioGroupModule],
    declarations: [CmpRadiogroupLabelComponent],
    exports: [CmpRadiogroupLabelComponent],
})
export class RadiogroupsComponentsModule {}
