import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material';
import { DxPopoverModule } from 'devextreme-angular';

import { CmpSlideInputComponent } from './slide-input/cmp-slide-input.component';

@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        MatSlideToggleModule,
        DxPopoverModule,
    ],
    exports: [CmpSlideInputComponent],
    declarations: [CmpSlideInputComponent],
    entryComponents: [],
    providers: [],
})
export class SlidesComponentsModule {}
