import { Injectable, ViewContainerRef } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material';
import { Observable } from 'rxjs';

import { AppService } from '../../app.service';
import { MessageDialogComponent } from './message-dialog.component';

@Injectable()
export class MessageDialogService {
    private _config = new MatDialogConfig();

    constructor(private _dialog: MatDialog, private _appService: AppService) {}

    message(
        title: string,
        message: any,
        viewContainerRef: ViewContainerRef,
        width?: string
    ): Observable<boolean> {
        let dialogRef: MatDialogRef<MessageDialogComponent>;

        this._config.viewContainerRef = viewContainerRef;
        this._config.disableClose = true;
        this._config.width = width;
        dialogRef = this._dialog.open(MessageDialogComponent, this._config);

        dialogRef.componentInstance.title = title;
        if (Array.isArray(message)) {
            let _messages: string = '';
            message.map((e) => {
                _messages = _messages.concat('- ', e, '<br>');
            });
            dialogRef.componentInstance.message = _messages;
        } else {
            dialogRef.componentInstance.message = message;
        }

        return dialogRef.afterClosed();
    }

    errorMessageFmt(
        e: any,
        viewContainerRef: ViewContainerRef,
        concatErrorMessages: boolean = false
    ): Observable<boolean> {
        this._appService.setFinishedProcessing();

        let message =
            'Ocorreu um erro interno no sistema. Por favor, ' +
            'tente executar a operação novamente e se o erro persistir, ' +
            'entre em contato com nosso suporte técnico.';

        if (e) {
            if (typeof e === 'string') {
                message = e;
            }

            if (e.errorMessage) {
                message = e.errorMessage;
            }

            if (concatErrorMessages) {
                if (e.errorMessages && Array.isArray(e.errorMessages)) {
                    if (message) {
                        message = message.concat('<br><br>');
                    }
                    e.errorMessages.slice(0, 5).map((m: string) => {
                        message = message.concat(m, '<br>');
                    });
                }
            }
        }

        let dialogRef: MatDialogRef<MessageDialogComponent>;
        this._config.viewContainerRef = viewContainerRef;

        dialogRef = this._dialog.open(MessageDialogComponent, this._config);

        dialogRef.componentInstance.title = 'Atenção.';
        dialogRef.componentInstance.message = message;

        return dialogRef.afterClosed();
    }
}
