import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'otk-cmp-raised-accent-button',
    templateUrl: './cmp-raised-accent-button.component.html',
    styleUrls: ['./cmp-raised-accent-button.component.scss'],
})
export class CmpRaisedAccentButtonComponent implements OnInit {
    @Input() enabled: boolean = true;
    @Input() tooltip: string = '';
    @Output() onClick = new EventEmitter<any>();

    constructor() {}

    ngOnInit() {}

    actionBtnClick(e): void {
        this.onClick.emit(e);
    }
}
