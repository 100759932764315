import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
    selector: 'otk-cmp-expand-compress-button',
    templateUrl: './cmp-expand-compress-button.component.html',
    styleUrls: ['./cmp-expand-compress-button.component.scss'],
})
export class CmpExpandCompressButtonComponent implements OnInit {
    @Input() expanded: boolean = true;
    @Output() expandChange: EventEmitter<boolean> = new EventEmitter();

    constructor() {}

    ngOnInit() {}

    actionBtnChange($event): void {
        this.expanded = !this.expanded;
        this.expandChange.emit(this.expanded);
    }
}
