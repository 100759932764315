import { finalize } from 'rxjs/operators';

import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AppService } from './app.service';

@Injectable()
export class AppProcessingInterceptor implements HttpInterceptor {
    constructor(private _appService: AppService) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (req.url.endsWith('/pedido/validar-edicao')) {
            return next.handle(req);
        }

        if (req.url.endsWith('/contaspagar/validar-edicao')) {
            return next.handle(req);
        }

        if (req.url.endsWith('/sistema/avisos')) {
            return next.handle(req);
        }

        return next.handle(req).pipe(
            tap(() => this._appService.setIsProcessing()),
            finalize(() => this._appService.setFinishedProcessing())
        );
    }
}
