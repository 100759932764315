import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'otk-cmp-log-button',
    templateUrl: './cmp-log-button.component.html',
    styleUrls: ['./cmp-log-button.component.scss'],
})
export class CmpLogButton implements OnInit {
    @Input() enabled: boolean = true;
    message: string;

    @Input() value: string = '';

    constructor() {}

    ngOnInit() {}

    ngOnChanges() {
        this.message = `Última Atualização: ${this.value}`;
    }
}
