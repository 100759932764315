import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DxTextBoxComponent } from 'devextreme-angular';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
    selector: 'otk-cmp-login-textbox-email',
    templateUrl: './cmp-login-textbox-email.component.html',
    styleUrls: ['./cmp-login-textbox-email.component.scss'],
})
export class CmpLoginTextboxEmailComponent implements OnInit {
    @Input() control: FormControl = new FormControl(null, []);
    @Input() placeholder: string = '';
    @Input() width: string = '100%';
    @Input() focus$: Subject<boolean> = new Subject<boolean>();
    @Input() label: string = 'E-mail';
    @Input() errorRequired: string = `Deve ser informado.`;
    @Input() errorInvalid: string = `Valor inválido.`;
    @ViewChild('ipt') inputEmail: DxTextBoxComponent;

    constructor() {}

    ngOnInit() {
        this.focus$.pipe(take(1)).subscribe(() => {
            setTimeout(() => {
                if (this.inputEmail && this.inputEmail.instance) {
                    this.inputEmail.instance.focus();
                }
            }, 800);
        });
    }

    ngAfterViewInit() {
        this.inputEmail.instance.focus();
    }

    ngOnDestroy() {}

    ngOnChanges() {}

    validationError(): any {
        if (!this.control) {
            return { message: null };
        }

        if (!this.control.errors) {
            return { message: null };
        } else {
            // console.log("CONTROL ERROR ->", this.control.errors, this.control.valid);
            if (this.control.errors.required) {
                return { message: this.errorRequired };
            } else {
                return {
                    message: this.errorInvalid
                        ? this.errorInvalid
                        : `Inválido.`,
                };
            }
        }
    }

    isValid(): boolean {
        if (!this.control) {
            return false;
        }

        if (this.control.pristine && this.control.untouched) {
            return true;
        }

        return !this.control.invalid;
    }
}
