import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DxSelectBoxModule } from 'devextreme-angular';

import { CmpSelectboxLabelComponent } from './cmp-selectbox-label.component';

@NgModule({
    imports: [CommonModule, ReactiveFormsModule, DxSelectBoxModule],
    declarations: [CmpSelectboxLabelComponent],
    exports: [CmpSelectboxLabelComponent],
})
export class SelectboxLabelModule {}
