import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
} from '@angular/material';

import { CmpSearchButtonComponent } from './cmp-search-button.component';

@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,

        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
    ],
    exports: [CmpSearchButtonComponent],
    declarations: [CmpSearchButtonComponent],
})
export class ButtonComponentSearchModule {}
