import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DxNumberBoxModule } from 'devextreme-angular';

import { CmpNumberboxSearchComponent } from './cmp-numberbox-search.component';

@NgModule({
    imports: [ReactiveFormsModule, DxNumberBoxModule],
    declarations: [CmpNumberboxSearchComponent],
    exports: [CmpNumberboxSearchComponent],
})
export class NumberboxSearchModule {}
