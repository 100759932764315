import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'otk-cmp-datagrid-area',
  templateUrl: './cmp-datagrid-area.component.html',
  styleUrls: ['./cmp-datagrid-area.component.scss']
})
export class CmpDatagridAreaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
