import { Directive, forwardRef, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
    selector: 'input[otk-validate-equal]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => EqualValidatorDirective),
            multi: true,
        },
    ],
})
export class EqualValidatorDirective implements Validator {
    constructor(
        @Attribute('otk-validate-equal') public validateEqual: string,
        @Attribute('reverse') public reverse: string
    ) {}

    private get isReverse() {
        if (!this.reverse) return false;
        return this.reverse === 'true' ? true : false;
    }

    validate(c: AbstractControl): { [key: string]: any } {
        // self value
        if (!c || c == undefined || !c.value) return null;

        if (!this.validateEqual || this.validateEqual == undefined) return null;

        let v = c.value;

        // control vlaue
        let e = c.root.get(this.validateEqual);
        if (!e || !e.value) return null;

        // value not equal
        if (e && v !== e.value && !this.isReverse) {
            return {
                validateEqual: false,
            };
        }

        // value equal and reverse
        if (e && v === e.value && this.isReverse) {
            if (e.errors) {
                delete e.errors['validateEqual'];
                if (!Object.keys(e.errors).length) e.setErrors(null);
            }
        }

        // value not equal and reverse
        if (e && v !== e.value && this.isReverse) {
            e.setErrors({
                validateEqual: false,
            });
        }

        return null;
    }
}
