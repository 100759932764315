import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
    DxDateBoxModule,
    DxPopoverModule,
    DxTemplateModule,
} from 'devextreme-angular';

import { CmpDateboxTimeDisabledLabelComponent } from './cmp-datebox-time-disabled-label.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        DxPopoverModule,
        DxTemplateModule,

        DxDateBoxModule,
    ],
    declarations: [CmpDateboxTimeDisabledLabelComponent],
    exports: [CmpDateboxTimeDisabledLabelComponent],
})
export class DateboxTimeDisabledLabelModule {}
