import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';

import { AppHelper } from '../../../../app.helper';

@Component({
    selector: 'otk-mat-input-basic-search',
    templateUrl: './mat-input-basic-search.component.html',
    styleUrls: ['./mat-input-basic-search.component.scss'],
})
export class MatInputBasicSearchComponent implements OnInit, OnDestroy {
    term: string = null;
    controlSearch: FormControl = new FormControl();
    focused = new EventEmitter();

    @Input() buttonSearchVisible: boolean = true;
    @Input() inputPlaceholder: string = 'Digite para pesquisar...';
    @Input() clear$: Subject<any> = new Subject();

    @Output() searchTerm = new EventEmitter<string>();
    @Output() searchClear = new EventEmitter<boolean>();

    private _searchSubscription: Subscription;
    private _clearSubscription: Subscription;

    constructor() {}

    ngOnInit() {
        this._clearSubscription = this.searchClear.subscribe(() =>
            this.controlSearch.setValue(null)
        );

        this._searchSubscription = this.controlSearch.valueChanges
            .pipe(
                debounceTime(400),
                filter((text) => text && text.length > 0),
                distinctUntilChanged()
            )
            .subscribe((t) => {
                this.searchTerm.emit(t);
            });

        this._searchSubscription = this.controlSearch.valueChanges
            .pipe(
                debounceTime(400),
                filter((text) => !text || text.length == 0),
                distinctUntilChanged()
            )
            .subscribe((t) => {
                this.searchClear.emit(t);
            });

        setTimeout(() => this.focused.emit(true), 200);
    }

    ngOnDestroy() {
        this._searchSubscription.unsubscribe();
        this._clearSubscription.unsubscribe();
    }

    actionBtnLimparPesquisa(): void {
        AppHelper.setCtrlValue(this.controlSearch, null);
        this.searchClear.emit(true);
    }

    actionBtnPesquisar(): void {
        if (!this.term) {
            this.focused.emit(true);
            return;
        }
        this.searchTerm.emit(this.term);
    }
}
