import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';

import { HandleErrorModel } from '../../core/models/handle-error.model';
import { AuthApiService } from './auth-api.service';
import * as act from './auth.actions';

@Injectable()
export class AuthEffects {
    constructor(private service: AuthApiService, private actions$: Actions) {}

    @Effect()
    autenticarLoginAction$ = this.actions$.pipe(
        ofType<act.AutenticarLoginAction>(act.AuthActions.AUTENTICAR_LOGIN),
        map((action: any) => action.payload),
        switchMap((payload: any) =>
            this.service.autenticar(payload.email, payload.senha).pipe(
                mergeMap((res) => {
                    return [
                        new act.LoginAutenticadoAction(res),
                        new act.RecuperarLoginAction(res),
                    ];
                }),
                catchError((error) => {
                    return this.handleError(error);
                })
            )
        )
    );

    @Effect()
    autenticarLoginInternoAction$ = this.actions$.pipe(
        ofType<act.AutenticarLoginInternoAction>(
            act.AuthActions.AUTENTICAR_LOGIN_INTERNO
        ),
        map((action: any) => action.payload),
        switchMap((payload: any) =>
            this.service
                .autenticarLoginInterno(
                    payload.email,
                    payload.senha,
                    payload.codigoDaEmpresa,
                    payload.codigoDoUsuario
                )
                .pipe(
                    mergeMap((res) => {
                        return [
                            new act.LoginAutenticadoAction(res),
                            new act.RecuperarLoginAction(res),
                        ];
                    }),
                    catchError((error) => this.handleError(error))
                )
        )
    );

    @Effect()
    autenticarLoginInternoAdminAction$ = this.actions$.pipe(
        ofType<act.AutenticarLoginInternoAdminAction>(
            act.AuthActions.AUTENTICAR_LOGIN_INTERNO_ADMIN
        ),
        map((action: any) => action.payload),
        switchMap((payload: any) =>
            this.service
                .autenticarLoginInternoAdmin(
                    payload.email,
                    payload.senha,
                    payload.codigoDaEmpresa
                )
                .pipe(
                    mergeMap((res) => {
                        return [
                            new act.LoginAutenticadoAction(res),
                            new act.RecuperarLoginAction(res),
                        ];
                    }),
                    catchError((error) => this.handleError(error))
                )
        )
    );

    @Effect()
    autenticarLoginClienteAction$ = this.actions$.pipe(
        ofType<act.AutenticarLoginClienteAction>(
            act.AuthActions.AUTENTICAR_LOGIN_CLIENTE
        ),
        map((action: any) => action.payload),
        switchMap((payload: any) =>
            this.service
                .autenticarLoginCliente(
                    payload.id,
                    payload.key,
                    payload.username
                )
                .pipe(
                    mergeMap((res) => {
                        return [
                            new act.LoginAutenticadoAction(res),
                            new act.RecuperarLoginAction(res),
                        ];
                    }),
                    catchError((error) => this.handleError(error))
                )
        )
    );

    @Effect()
    autenticarLoginBaseOTKAction$ = this.actions$.pipe(
        ofType<act.AutenticarLoginBaseOTKAction>(
            act.AuthActions.AUTENTICAR_LOGIN_BASE_OTK
        ),
        map((action: any) => action.payload),
        switchMap((payload: any) =>
            this.service
                .autenticarLoginBaseOTK(payload.email, payload.senha)
                .pipe(
                    mergeMap((res) => {
                        return [
                            new act.LoginAutenticadoAction(res),
                            new act.RecuperarLoginAction(res),
                        ];
                    }),
                    catchError((error) => this.handleError(error))
                )
        )
    );

    @Effect()
    autenticarLoginBaseAfixGrafAction$ = this.actions$.pipe(
        ofType<act.AutenticarLoginBaseAfixGrafAction>(
            act.AuthActions.AUTENTICAR_LOGIN_BASE_AFIXGRAF
        ),
        map((action: any) => action.payload),
        switchMap((payload: any) =>
            this.service
                .autenticarLoginBaseAfixGraf(payload.email, payload.senha)
                .pipe(
                    mergeMap((res) => {
                        return [
                            new act.LoginAutenticadoAction(res),
                            new act.RecuperarLoginAction(res),
                        ];
                    }),
                    catchError((error) => this.handleError(error))
                )
        )
    );

    @Effect()
    refreshTokenAction$ = this.actions$.pipe(
        ofType<act.RefreshTokenAction>(act.AuthActions.REFRESH_TOKEN),
        map((action: any) => action.payload),
        filter((payload: any) => payload),
        switchMap((token) =>
            this.service.refreshToken(token).pipe(
                mergeMap((res) => {
                    return [
                        new act.LoginAutenticadoAction(res),
                        new act.RecuperarLoginAction(res),
                    ];
                }),
                catchError((error) => {
                    return this.handleError(error);
                })
            )
        )
    );

    @Effect()
    recuperarLoginAction$ = this.actions$.pipe(
        ofType<act.RecuperarLoginAction>(act.AuthActions.RECUPERAR_LOGIN),
        map((action: any) => action.payload),
        filter((payload: any) => payload),
        switchMap((token) =>
            this.service.obterInformacoesDoLogin(token).pipe(
                map((res) => new act.LoginRecuperadoComSucessoAction(res)),
                catchError((error) => {
                    if (error && error.status == 401) {
                        let e = new HandleErrorModel(error);
                        e.errorMessage = 'Login inválido ou expirado.';
                        return of(new act.ErroAction(e));
                    }
                    return this.handleError(error);
                })
            )
        )
    );

    @Effect()
    registrarLoginAction$ = this.actions$.pipe(
        ofType<act.RegistrarLoginAction>(act.AuthActions.REGISTRAR_LOGIN),
        switchMap(() =>
            this.service.registrarLogin().pipe(
                map((res) => {
                    console.log('LOGIN REGISTRADO COM SUCESSO');
                    return new act.RegistrarLoginComSucessoAction(res);
                }),
                catchError((error) => {
                    console.log('ERRO REGISTRANDO LOGIN');
                    let e = new HandleErrorModel(error);
                    return of(new act.ErroExecutandoOperacaoAction(e));
                })
            )
        )
    );

    @Effect()
    registrarLogoutAction$ = this.actions$.pipe(
        ofType<act.RegistrarLogoutAction>(act.AuthActions.REGISTRAR_LOGOUT),
        switchMap(() =>
            this.service.registrarLogout().pipe(
                map((res) => {
                    console.log('LOGOUT REGISTRADO COM SUCESSO');
                    return new act.RegistrarLogoutComSucessoAction(res);
                }),
                catchError((error) => {
                    console.log('ERRO REGISTRANDO LOGOUT');
                    let e = new HandleErrorModel(error);
                    return of(new act.ErroExecutandoOperacaoAction(e));
                })
            )
        )
    );

    @Effect()
    enviarNovaSenhaAction$ = this.actions$.pipe(
        ofType<act.EnviarNovaSenhaAction>(act.AuthActions.ENVIAR_NOVA_SENHA),
        map((action: any) => action.payload),
        switchMap((payload: any) =>
            this.service.enviarNovaSenha(payload).pipe(
                map((res) => new act.EnviarNovaSenhaComSucessoAction(res)),
                catchError((error) => this.handleError(error))
            )
        )
    );

    @Effect()
    criarNovaContaAction$ = this.actions$.pipe(
        ofType<act.CriarNovaContaAction>(act.AuthActions.CRIAR_NOVA_CONTA),
        map((action: any) => action.payload),
        filter((payload: any) => payload),
        switchMap((payload: any) =>
            this.service
                .criarNovaConta(
                    payload.nomeDaEmpresa,
                    payload.nomeDoUsuario,
                    payload.email,
                    payload.senha,
                    payload.telefone,
                    payload.consentimentoDeComunicacao,
                    payload.utmSource,
                    payload.utmMedium,
                    payload.utmCampaign
                )
                .pipe(
                    map((res) => new act.CriarNovaContaComSucessoAction(res)),
                    catchError((error) => this.handleError(error))
                )
        )
    );

    @Effect()
    confirmarEmailSenha$ = this.actions$.pipe(
        ofType<act.ConfirmarEmailSenhaAction>(
            act.AuthActions.CONFIRMAR_EMAIL_SENHA
        ),
        map((action: any) => action.payload),
        filter((payload: any) => payload),
        switchMap((payload: any) =>
            this.service
                .confirmarEmailSenha(
                    payload.email,
                    payload.novaSenha,
                    payload.token
                )
                .pipe(
                    map(
                        (res) =>
                            new act.ConfirmarEmailSenhaComSucessoAction(res)
                    ),
                    catchError((error) => this.handleError(error))
                )
        )
    );

    @Effect()
    redefinirSenha$ = this.actions$.pipe(
        ofType<act.RedefinirSenhaAction>(act.AuthActions.REDEFINIR_SENHA),
        map((action: any) => action.payload),
        filter((payload: any) => payload),
        switchMap((payload: any) =>
            this.service
                .redefinirSenha(payload.email, payload.novaSenha, payload.token)
                .pipe(
                    map((res) => new act.RedefinirSenhaComSucessoAction(res)),
                    catchError((error) => this.handleError(error))
                )
        )
    );

    private handleError(error: any) {
        const e = new HandleErrorModel(error);
        return of(new act.ErroAction(e));
    }
}
