import {
    Component,
    Input,
    Output,
    OnDestroy,
    OnInit,
    EventEmitter,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StateModeEnum } from '../../../../app.enums';

@Component({
    selector: 'otk-cmp-title-content-area',
    templateUrl: './cmp-title-content-area.component.html',
    styleUrls: ['./cmp-title-content-area.component.scss'],
})
export class CmpTitleContentAreaComponent implements OnInit, OnDestroy {
    @Input() title: string = 'XXXX';
    @Input() subTitle: string = '';

    visibleState: boolean = true;
    @Input() state$: BehaviorSubject<StateModeEnum> =
        new BehaviorSubject<StateModeEnum>(StateModeEnum.undefined);
    @Input() visible: boolean = true;

    @Input() buttonHelpVisible: boolean = false;
    @Input() buttonHelpEnabled: boolean = false;
    @Output() buttonHelpClick: EventEmitter<any> = new EventEmitter();

    constructor() {}

    ngOnInit() {
        this.state$.subscribe((r) => {
            this.visibleState =
                r == StateModeEnum.browse || r == StateModeEnum.selected;
        });
    }

    ngOnDestroy(): void {}

    ngOnChanges(): void {}

    actionButtonHelpClick($event): void {
        if (this.buttonHelpEnabled) {
            this.buttonHelpClick.emit($event);
        }
    }
}
