import { Action, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

import { AppState } from './app.reducer';

export abstract class StoreService {
    protected readonly STATE;
    protected store: Store<AppState>;

    protected storeSelectFeatureState() {
        // return this.store.map(this.STATE);
        // return this.store.pipe(map(this.STATE));
        return this.store.select(this.STATE);
    }

    protected dispatchAction(action: Action) {
        this.store.dispatch(action);
    }
}
