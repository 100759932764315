import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { MatMenu, MatMenuTrigger } from '@angular/material';
import { BehaviorSubject } from 'rxjs';

import { StateModeEnum } from '../../../../app.enums';

@Component({
    selector: 'otk-cmp-tools-button',
    templateUrl: './cmp-tools-button.component.html',
    styleUrls: ['./cmp-tools-button.component.scss'],
})
export class CmpToolsButton implements OnInit {
    visibleState: boolean = true;

    @Input() state$: BehaviorSubject<StateModeEnum> =
        new BehaviorSubject<StateModeEnum>(StateModeEnum.undefined);
    @Input() visible: boolean = true;

    @Input() buttonNewVisible: boolean = false;
    @Input() buttonNewEnabled: boolean = false;
    @Output() buttonNewClick: EventEmitter<any> = new EventEmitter();

    @Input() buttonEditVisible: boolean = true;
    @Input() buttonEditEnabled: boolean = true;
    @Output() buttonEditClick: EventEmitter<any> = new EventEmitter();

    @Input() buttonRemoveVisible: boolean = true;
    @Input() buttonRemoveEnabled: boolean = true;
    @Output() buttonRemoveClick: EventEmitter<any> = new EventEmitter();

    @Input() buttonPrintVisible: boolean = false;
    @Input() buttonPrintEnabled: boolean = false;
    @Output() buttonPrintClick: EventEmitter<any> = new EventEmitter();

    @Input() buttonTimelineVisible: boolean = false;
    @Input() buttonTimelineEnabled: boolean = false;
    @Output() buttonTimelineClick: EventEmitter<any> = new EventEmitter();

    @Input() buttonHelpVisible: boolean = false;
    @Input() buttonHelpEnabled: boolean = false;
    @Output() buttonHelpClick: EventEmitter<any> = new EventEmitter();

    @Input() menuEdit: MatMenu = null;
    @Input() menuEditEnabled: boolean = true;
    @ViewChild('menuEditTrigger') menuEditTrigger: MatMenuTrigger;

    @Input() menuPrintVisible: boolean = true;
    @Input() menuPrint: MatMenu = null;
    @ViewChild('menuPrintTrigger') menuPrintTrigger: MatMenuTrigger;

    @Input() buttonRefreshVisible: boolean = false;
    @Input() buttonRefreshEnabled: boolean = false;
    @Output() buttonRefreshClick: EventEmitter<any> = new EventEmitter();

    @Input() buttonExpandVisible: boolean = false;
    @Input() expandedState: boolean = true;
    @Output() buttonExpandClick: EventEmitter<boolean> = new EventEmitter();

    @Input() buttonLogVisible: boolean = false;
    @Input() value: string = '';

    constructor() {}

    ngOnInit() {
        this.state$.subscribe((r) => {
            this.visibleState =
                r == StateModeEnum.browse || r == StateModeEnum.selected;
        });
    }

    actionButtonNewClick($event): void {
        if (this.buttonNewEnabled) {
            this.buttonNewClick.emit($event);
        }
    }

    actionButtonEditClick($event): void {
        if (this.menuEdit && this.menuEditEnabled && this.menuEditTrigger) {
            this.menuEditTrigger.openMenu();
            return;
        }

        if (this.buttonEditEnabled) {
            this.buttonEditClick.emit($event);
        }
    }

    actionButtonRemoveClick($event): void {
        if (this.buttonRemoveEnabled) {
            this.buttonRemoveClick.emit($event);
        }
    }

    actionButtonPrintClick($event): void {
        if (this.menuPrint && this.menuPrintTrigger) {
            this.menuPrintTrigger.openMenu();
            return;
        }

        if (this.buttonPrintEnabled) {
            this.buttonPrintClick.emit($event);
        }
    }

    actionButtonTimelineClick($event): void {
        if (this.buttonTimelineEnabled) {
            this.buttonTimelineClick.emit($event);
        }
    }

    actionButtonRefreshClick($event): void {
        if (this.buttonRefreshEnabled) {
            this.buttonRefreshClick.emit($event);
        }
    }

    actionButtonHelpClick($event): void {
        if (this.buttonHelpEnabled) {
            this.buttonHelpClick.emit($event);
        }
    }

    actionButtonExpandChange($event: boolean): void {
        this.buttonExpandClick.emit($event);
    }
}
