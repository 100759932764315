import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MessageNoRegisterComponent } from './message-no-register.component';

@NgModule({
    imports: [CommonModule, FlexLayoutModule],
    exports: [MessageNoRegisterComponent],
    declarations: [MessageNoRegisterComponent],
})
export class MessageNoRegisterModule {}
