import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { AuthService } from '../../auth/services/auth.service';

@Component({
    selector: 'app-message-informacao-dialog',
    templateUrl: './message-informacao-dialog.component.html',
    styleUrls: ['./message-informacao-dialog.component.scss'],
})
export class MessageInformacaoDialogComponent implements OnInit {
    public title: string;
    public message: string;

    constructor(
        public dialogRef: MatDialogRef<MessageInformacaoDialogComponent>,
        private _authService: AuthService
    ) {}

    ngOnInit() {}

    actionBtnFechar(): void {
        this.dialogRef.close(true);
    }
}
