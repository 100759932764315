import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
    DxDateBoxModule,
    DxPopoverModule,
    DxTemplateModule,
} from 'devextreme-angular';

import { CmpDateboxTimeOnlyLabelComponent } from './cmp-datebox-time-only-label.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        DxPopoverModule,
        DxTemplateModule,

        DxDateBoxModule,
    ],
    declarations: [CmpDateboxTimeOnlyLabelComponent],
    exports: [CmpDateboxTimeOnlyLabelComponent],
})
export class DateboxTimeOnlyLabelModule {}
