import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'otk-cmp-textarea',
    templateUrl: './cmp-textarea.component.html',
    styleUrls: ['./cmp-textarea.component.scss'],
})
export class CmpTextareaComponent implements OnInit {
    @Input() control: FormControl = new FormControl(null, []);
    @Input() placeholder: string = '';
    @Input() eventValue: string = 'keyup';
    @Input() autoResizeEnabled: boolean = false;
    @Input() height: string = '60';
    @Input() width: string = '100%';
    @Input() maxHeight: string = null;
    @Input() showClearButton: boolean = true;
    @Input() validationMessageMode: string = 'always';
    @Input() errorRequired: string = `Deve ser informado.`;

    constructor() {}

    ngOnInit() {}

    ngOnDestroy() {}

    ngOnChanges() {}

    isValid(): boolean {
        if (!this.control) {
            return false;
        }

        if (this.control.pristine && this.control.untouched) {
            return true;
        }

        return !this.control.invalid;
    }

    actionKeyDown(e: any) {
        if (e && e.event) {
            e.event.stopPropagation();
        }
    }

    validationError(): any {
        if (!this.control) {
            return { message: null };
        }

        if (!this.control.errors) {
            return { message: null };
        } else {
            // console.log("CONTROL ERROR ->", this.control.errors, this.control.valid);
            if (this.control.errors.required) {
                return { message: this.errorRequired };
            } else if (this.control.errors.minlength) {
                return {
                    message: `Deve possuir no mínimo ${this.control.errors.minlength.requiredLength} caracteres.`,
                };
            } else if (this.control.errors.maxlength) {
                return {
                    message: `Deve possuir no máximo ${this.control.errors.maxlength.requiredLength} caracteres.`,
                };
            } else {
                return { message: `Inválido.` };
            }
        }
    }
}
