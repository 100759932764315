import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'otk-cmp-checkbox-input',
    templateUrl: './cmp-checkbox-input.component.html',
    styleUrls: ['./cmp-checkbox-input.component.scss'],
})
export class CmpCheckboxInputComponent implements OnInit {
    @Input() caption: string = '';
    @Input() tooltip: string = '';
    @Input() hint: string = null;
    @Input() control: FormControl = new FormControl(null, []);

    constructor() {}

    ngOnInit() {}
}
