import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';

import { StateModeEnum } from '../../../../app.enums';
import { AppHelper } from '../../../../app.helper';
import { MessageService } from '../../../../shared-services/message/message.service';

@Component({
    selector: 'otk-cmp-buttons-save-remove-dialog',
    templateUrl: './cmp-buttons-save-remove-dialog.component.html',
    styleUrls: ['./cmp-buttons-save-remove-dialog.component.scss'],
})
export class CmpButtonsSaveRemoveDialogComponent implements OnInit {
    @Input() captionAdd: string = 'Adicionar';
    @Input() captionAddContinue: string = 'Adicionar e continuar';
    @Input() captionEdit: string = 'Salvar';

    @Input() enabledAddEdit: boolean = true;
    @Input() continueOption: boolean = true;
    @Input() state$: BehaviorSubject<StateModeEnum> =
        new BehaviorSubject<StateModeEnum>(StateModeEnum.undefined);
    @Input() valid$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );
    @Input() insertVisible: boolean = true;
    @Input() editVisible: boolean = true;
    @Input() removeVisible: boolean = true;
    @Input() form: FormGroup = null;

    @Output() saveClick: EventEmitter<boolean> = new EventEmitter();
    @Output() removeClick: EventEmitter<any> = new EventEmitter();
    @Output() continueOptionChange: EventEmitter<any> = new EventEmitter();
    @Output() invalidFormEvent: EventEmitter<any> = new EventEmitter();

    visible: boolean = false;
    enabled: boolean = false;

    isInsert: boolean = false;
    isEdit: boolean = false;
    continueAdd: boolean = false;
    validateForm: boolean = false;

    private _stateSubscription: Subscription;
    private _validSubscription: Subscription;

    constructor(private _message: MessageService) {}

    ngOnInit() {
        this._stateSubscription = this.state$.subscribe((r) => {
            this.visible = r == StateModeEnum.insert || r == StateModeEnum.edit;
            this.isInsert = r == StateModeEnum.insert;
            this.isEdit = r == StateModeEnum.edit;

            this.continueAdd = this.isInsert;
            this.continueOptionChange.emit(this.continueAdd);
        });

        this._validSubscription = this.valid$.subscribe((r) => {
            this.enabled = r;
        });

        if (this.form) {
            this.validateForm = true;
        }
    }

    ngOnDestroy() {
        this._stateSubscription.unsubscribe();
        this._validSubscription.unsubscribe();
    }

    actionButtonSaveClick(): void {
        if (this.validateForm) {
            if (!this.valid$.value) {
                this.invalidFormEvent.emit();
                this._message.notificationErrorMessage(
                    'Por favor, verifique os campos destacados.'
                );
                AppHelper.markControlsDirtyAndTouched(this.form);
                AppHelper.logControlsInvalid(this.form);
                return;
            }
        }
        this.saveClick.emit(
            this.isInsert && this.continueOption && this.continueAdd
        );
    }

    actionButtonRemoveClick(e: any): void {
        this.removeClick.emit(e);
    }

    actionButtonContinueChange(e: any): void {
        this.continueOptionChange.emit(e == true);
    }
}
