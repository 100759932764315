import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

import { AppHelper } from '../../../.././app.helper';
import { MessageService } from '../../../.././shared-services/message/message.service';
import { StateModeEnum } from '../../../../app.enums';

@Component({
    selector: 'otk-cmp-buttons-save-cancel-dialog',
    templateUrl: './cmp-buttons-save-cancel-dialog.component.html',
    styleUrls: ['./cmp-buttons-save-cancel-dialog.component.scss'],
})
export class CmpButtonsSaveCancelDialogComponent implements OnInit {
    @Input() captionAdd: string = 'Adicionar';
    @Input() captionEdit: string = 'Salvar';
    @Input() enabledAddEdit: boolean = true;
    @Input() state$: BehaviorSubject<StateModeEnum> =
        new BehaviorSubject<StateModeEnum>(StateModeEnum.undefined);
    @Input() valid$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );
    @Input() form: FormGroup = null;

    @Output() saveClick: EventEmitter<boolean> = new EventEmitter();
    @Output() cancelClick: EventEmitter<any> = new EventEmitter();
    @Output() invalidFormEvent: EventEmitter<any> = new EventEmitter();

    visible: boolean = true;
    enabled: boolean = false;
    validateForm: boolean = false;

    isInsert: boolean = false;
    isEdit: boolean = false;

    constructor(private _message: MessageService) {}

    ngOnInit() {
        this.state$.subscribe((r) => {
            this.visible = r == StateModeEnum.insert || r == StateModeEnum.edit;
            this.isInsert = r == StateModeEnum.insert;
            this.isEdit = r == StateModeEnum.edit;
        });

        this.valid$.subscribe((r) => {
            this.enabled = r;
        });

        if (this.form) {
            this.validateForm = true;
        }
    }

    actionButtonSaveClick(e: any): void {
        if (this.validateForm) {
            this.form.updateValueAndValidity();

            if (!this.valid$.value) {
                this.invalidFormEvent.emit();
                this._message.notificationErrorMessage(
                    'Por favor, verifique os campos destacados.'
                );
                AppHelper.markControlsDirtyAndTouched(this.form);
                AppHelper.logControlsInvalid(this.form);
                return;
            }
        }

        this.saveClick.emit(this.isInsert);
    }

    actionButtonCancelClick(e: any): void {
        this.cancelClick.emit(e);
    }

    get botaoDesabilitado(): boolean {
        if (this.validateForm && (this.isEdit || this.isInsert)) {
            return false;
        }
        return !this.enabledAddEdit || !this.enabled;
    }
}
