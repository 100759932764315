import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { CustomValidators } from 'ng2-validation';
import { AppHelper } from '../../app.helper';
import { ContratacaoQuantidadeFilialApiService } from './store/contratacao-quantidade-filial-api.service';

@Component({
    selector: 'app-contratacao-quantidade-filial-dialog',
    templateUrl: './contratacao-quantidade-filial-dialog.component.html',
    styleUrls: ['./contratacao-quantidade-filial-dialog.component.scss'],
})
export class ContratacaoQuantidadeFilialDialogComponent implements OnInit {
    public title: string;
    public message: string;

    form: FormGroup;
    valorDoLancamento: FormControl;
    quantidade: FormControl;
    valorUnitario: FormControl;

    constructor(
        public dialogRef: MatDialogRef<ContratacaoQuantidadeFilialDialogComponent>,
        private apiService: ContratacaoQuantidadeFilialApiService
    ) {}

    ngOnInit() {
        this.configurarForm();
        this._setValorUnitario();
        this._setValorTotal();
    }

    carregarControls(obj: any) {
        if (!this.form || !obj) {
            return;
        }

        AppHelper.setCtrlValue(this.valorDoLancamento, obj.valorDoLancamento);
        AppHelper.setCtrlValue(this.quantidade, obj.quantidade);
        AppHelper.setCtrlValue(this.valorUnitario, obj.valorUnitario);

        this.form.updateValueAndValidity();
    }

    configurarForm(): void {
        this.valorDoLancamento = new FormControl(
            { value: null, disabled: true },
            []
        );

        this.quantidade = new FormControl(1, [
            CustomValidators.min(1),
            CustomValidators.max(999999),
        ]);

        this.valorUnitario = new FormControl(60, [
            CustomValidators.max(999999),
        ]);

        this.form = new FormGroup({
            valorDoLancamento: this.valorDoLancamento,
            quantidade: this.quantidade,
            valorUnitario: this.valorUnitario,
        });

        this.quantidade.valueChanges.subscribe(() => {
            this._setValorTotal();
        });

        this.valorUnitario.valueChanges.subscribe(() => {
            this._setValorTotal();
        });
    }

    private _setValorUnitario(): void {
        AppHelper.setCtrlValue(this.valorUnitario, 60);
    }

    private _setValorTotal(): void {
        const r = this.quantidade.value * this.valorUnitario.value;

        this.valorDoLancamento.setValue(
            r.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
            }),
            { emitEvent: false }
        );
    }

    confirmar(): void {
        const obj = {
            quantidade: this.quantidade.value,
            valorUnitario: this.valorUnitario.value,
        };

        this.apiService
            .inserirFiliais(this.quantidade.value, this.valorUnitario.value)
            .subscribe(() => {
                this.dialogRef.close(true);
            });
    }
}
