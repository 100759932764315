import {
    Directive,
    ElementRef,
    Input,
    Renderer,
    HostListener,
} from '@angular/core';
import { md5 } from './md5';

@Directive({
    selector: '[gravatar]',
})
export class GravatarImageDirective {
    @Input('email') email: string;
    @Input('size') size: number = 16;
    @Input('fallback') fallback: string = 'mm';

    @HostListener('error', ['$event']) onBlur($event: any) {
        this._renderer.setElementProperty(
            this.elementRef.nativeElement,
            'src',
            this._defaultUrl()
        );
    }

    constructor(private _renderer: Renderer, private elementRef: ElementRef) {}

    ngOnInit() {
        this._locate();
    }

    ngOnChanges() {
        this._locate();
    }

    private _defaultUrl(): string {
        return 'assets/img/avatars/noavatar.png';
    }

    private _locate(): void {
        let result = this._defaultUrl();

        if (this.email) {
            result = `https://www.gravatar.com/avatar/${md5(this.email)}?s=${
                this.size
            }&d=${this.fallback}`;
        }

        this._renderer.setElementProperty(
            this.elementRef.nativeElement,
            'src',
            result
        );
    }
}
