import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'otk-cmp-pdf-button',
    templateUrl: './cmp-pdf-button.component.html',
    styleUrls: ['./cmp-pdf-button.component.scss'],
})
export class CmpPdfButtonComponent implements OnInit {
    @Input() color: string = 'primary';
    @Input() enabled: boolean = true;
    @Input() tooltip: string = '';

    constructor() {}

    ngOnInit() {}
}
