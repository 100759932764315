import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { DxNumberBoxComponent } from 'devextreme-angular';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import { AppHelper } from './../../../../app.helper';

@Component({
    selector: 'otk-cmp-numberbox',
    templateUrl: './cmp-numberbox.component.html',
    styleUrls: ['./cmp-numberbox.component.scss'],
})
export class CmpNumberboxComponent implements OnInit {
    @Input() control: FormControl = new FormControl('', []);
    @Input() format: string = '#,##0.00';
    @Input() placeholder: string = '';
    @Input() width: string = '100%';
    @Input() showClearButton: boolean = false;
    @Input() showCurrency: boolean = false;
    @Input() showSpinButtons: boolean = false;
    @Input() min: number = null;
    @Input() max: number = null;
    @Input() readOnly: boolean = false;
    @Input() validationMessageMode: string = 'always';
    @Input() errorRequired: string = `Deve ser informado.`;
    @Input() errorGt: string = null;
    @Input() errorMin: string = null;
    @Input() errorMax: string = null;
    @Input() acceptNull: boolean = false;
    @Input() focus$: Subject<boolean> = new Subject<boolean>();

    @Output() onEnterKeyEvent = new EventEmitter<any>();

    @Output() onClearClick = new EventEmitter<any>();

    @ViewChild(DxNumberBoxComponent) numberBox: DxNumberBoxComponent;

    popoverVisible: boolean;

    constructor() {}

    ngOnInit() {
        if (this.acceptNull) {
        } else {
            if (
                this.control &&
                (this.control.value == null || this.control.value == undefined)
            ) {
                AppHelper.setCtrlValue(this.control, 0);
            }
        }

        this.focus$.pipe(take(1)).subscribe(() => {
            setTimeout(() => {
                if (this.numberBox && this.numberBox.instance) {
                    this.numberBox.instance.focus();
                }
            }, 800);
        });
    }

    ngOnDestroy() {}

    ngOnChanges() {
        if (this.showCurrency) {
            this.format = 'R$ #,##0.00';
        }
    }

    actionValueChanged(e: any) {
        if (e.value === null) {
            if (this.acceptNull) {
            } else {
                e.component.option('value', 0);
            }
        }
    }

    isValid(): boolean {
        if (!this.control) {
            return false;
        }

        if (this.control.pristine && this.control.untouched) {
            return true;
        }

        return !this.control.invalid;
    }

    validationError(): any {
        if (!this.control) {
            return { message: null };
        }

        if (!this.control.errors) {
            return { message: null };
        } else {
            // console.log("CONTROL ERROR ->", this.control.errors, this.control.valid);
            if (this.control.errors.required) {
                return { message: this.errorRequired };
            } else if (this.control.errors.gt) {
                return {
                    message: this.errorGt
                        ? this.errorGt
                        : `Inválido. Deve ser maior ou igual que ${AppHelper.numberToFmtStr(
                              this.control.errors.requiredValue
                          )}.`,
                };
            } else if (this.control.errors.min) {
                // console.log("ERRROMIN", this.control.errors.min)
                return {
                    message: this.errorMin
                        ? this.errorMin
                        : `Deve ser maior ou igual que ${AppHelper.numberToFmtStr(
                              this.control.errors.requiredValue
                          )}.`,
                };
            } else if (this.control.errors.max) {
                // console.log("ERRROMAX", this.control.errors.max)
                return {
                    message: this.errorMax
                        ? this.errorMax
                        : `Deve ser menor ou igual que ${AppHelper.numberToFmtStr(
                              this.control.errors.requiredValue
                          )}.`,
                };
            } else {
                return { message: `Inválido.` };
            }
        }
    }

    actionContentReady(e: any) {
        // console.log("CONTENT READY", e);
        const selector = e.element.querySelector('.dx-icon-clear');
        // console.log("SELECTOR", selector);
        if (selector) {
            selector.addEventListener('click', () => this.onClearClick.emit());
        }
    }

    actionOnEnterKey(e: any) {
        this.onEnterKeyEvent.emit(e);
    }

    onFocusIn(e: any) {
        let inputEl = e.element.querySelector('.dx-texteditor-input');
        if (inputEl) {
            inputEl.selectionStart = 0;
            inputEl.selectionEnd = inputEl.value.length;
        }
    }
}
