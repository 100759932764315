import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
    DxPopoverModule,
    DxTextBoxModule,
    DxValidationGroupModule,
    DxValidatorModule,
} from 'devextreme-angular';

import { CmpLoginTextboxSenhaComponent } from './cmp-login-textbox-senha.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        DxTextBoxModule,
        DxValidatorModule,
        DxValidationGroupModule,
        DxPopoverModule,
    ],
    declarations: [CmpLoginTextboxSenhaComponent],
    exports: [CmpLoginTextboxSenhaComponent],
})
export class LoginTextboxSenhaModule {}
