import { Component, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
    private _routerSubscription: Subscription;

    constructor(private _router: Router) {}

    ngOnInit() {
        // const script = document.createElement('script');
        // script.src = `./assets/appversion.js?v=${AppSettings.APP_VERSION}`;
        // document.head.appendChild(script);

        this._routerSubscription = this._router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                // console.log("NAVIGATION END -> ", event);
            }
        });
    }

    ngOnDestroy() {
        this._routerSubscription.unsubscribe();
    }
}
