import {
    Directive,
    Input,
    Inject,
    ElementRef,
    EventEmitter,
} from '@angular/core';

@Directive({
    selector: '[otk-focus]',
})
export class FocusDirective {
    private _focusEmitterSubscription;

    @Input('otk-focus') set focus(focusEmitter: EventEmitter<boolean>) {
        if (this._focusEmitterSubscription) {
            this._focusEmitterSubscription.unsubscribe();
        }

        this._focusEmitterSubscription = focusEmitter.subscribe(
            (() => this.element.nativeElement.focus()).bind(this)
        );
    }

    constructor(@Inject(ElementRef) private element: ElementRef) {}
}
