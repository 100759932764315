import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
    selector: 'app-contratacao-mensagem-dialog',
    templateUrl: './contratacao-mensagem-dialog.component.html',
    styleUrls: ['./contratacao-mensagem-dialog.component.scss'],
})
export class ContratacaoMensagemDialogComponent implements OnInit {
    public title: string;
    public message: string;

    constructor(
        public dialogRef: MatDialogRef<ContratacaoMensagemDialogComponent>
    ) {}

    ngOnInit() {}
}
