import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DxDateBoxComponent } from 'devextreme-angular';
import { AppHelper } from '../../../../app.helper';
@Component({
    selector: 'otk-cmp-datebox-time-disabled-label',
    templateUrl: './cmp-datebox-time-disabled-label.component.html',
    styleUrls: ['./cmp-datebox-time-disabled-label.component.scss'],
})
export class CmpDateboxTimeDisabledLabelComponent implements OnInit {
    control: FormControl = new FormControl(null, []);

    @Input() label: string = null;
    @Input() help: string = null;
    @Input() value: any = null;
    @Input() displayFormat: string = 'shortDateShortTime'; // "shortdate"
    @Input() width: string = '150px';
    @Input() widthHelp: string = '300px';

    popoverVisible: boolean;

    @ViewChild(DxDateBoxComponent) dateBox: DxDateBoxComponent;

    constructor() {}

    ngOnInit() {}

    ngOnDestroy() {}

    ngOnChanges() {
        AppHelper.setCtrlValue(this.control, this.value);
        AppHelper.setCtrlEnable(this.control, false);
    }

    togglePopover() {
        this.popoverVisible = !this.popoverVisible;
    }
}
