import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PermissoesCrm } from '../models/auth-user.model';
import { AuthService } from '../services/auth.service';

@Injectable()
export class CrmAuthService {
    acessarCadastrosCrm$: Observable<boolean>;
    acessarCadastrosLoginInteroCrm$: Observable<boolean>;

    responsavelCrm$: Observable<boolean>;

    constructor(public authService: AuthService) {
        this.acessarCadastrosCrm$ = this.authService.permissoesCrm$.pipe(
            map((r: PermissoesCrm) => r.responsavel)
        );

        this.acessarCadastrosLoginInteroCrm$ =
            this.authService.loginInterno$.pipe(map((r: boolean) => r));

        this.responsavelCrm$ = this.authService.permissoesFinanceiro$.pipe(
            map((r: PermissoesCrm) => r.responsavel)
        );
    }
}
