import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import { hasRequiredField } from '../../../../app.functions';
import { MessageService } from '../../../../shared-services/message/message.service';
import { AppHelper } from './../../../../app.helper';

@Component({
    selector: 'otk-cmp-textbox-label-center',
    templateUrl: './cmp-textbox-label-center.component.html',
    styleUrls: ['./cmp-textbox-label-center.component.scss'],
})
export class CmpTextboxLabelCenterComponent implements OnInit {
    @Input() label: string = null;
    @Input() changeLabel: boolean = true;
    @Input() help: string = null;
    @Input() copyButton: boolean = true;
    @Input() control: FormControl = new FormControl(null, []);
    @Input() placeholder: string = '';
    @Input() width: string = '100%';
    @Input() widthHelp: string = '300px';
    @Input() mask: string = null;
    @Input() maskInvalidMessage: string = `Valor inválido.`;
    @Input() useMaskedValue: boolean = true;
    @Input() showClearButton: boolean = true;
    @Input() upper: boolean = false;
    @Input() validationMessageMode: string = 'always';
    @Input() errorRequired: string = `Deve ser informado.`;
    @Input() errorInvalid: string = `Valor inválido.`;
    @Input() focus$: Subject<boolean> = new Subject<boolean>();
    @Input() noMarginBottom: boolean = false;

    @Output() onValidMask = new EventEmitter<any>();
    @Output() onClearClick = new EventEmitter<any>();
    @Output() onEnterKeyEvent = new EventEmitter<any>();

    popoverVisible: boolean = false;
    upperCaseValue: string = null;

    constructor(private _message: MessageService) {}

    ngOnInit() {
        if (this.upper) {
            this.control.valueChanges.subscribe(() => {
                if (this.control.value) {
                    this.upperCaseValue = this.control.value.toUpperCase();
                    AppHelper.setCtrlValue(this.control, this.upperCaseValue);
                }
            });
        }
    }

    ngOnDestroy() {}

    ngOnChanges() {}

    get _caption(): string {
        return this.label.replace(/\*/g, ' ');
    }

    get _captionLabel(): string {
        if (!this.changeLabel) {
            return this.label;
        }

        if (this.control && hasRequiredField(this.control)) {
            let t = this.label.replace(/\*/g, ' ');
            return `${t} *`;
        }
        return this.label;
    }

    actionOnValidMask(e: any) {
        this.onValidMask.emit(e);
    }

    actionOnClearClick(e: any) {
        this.onClearClick.emit(e);
    }

    actionOnEnterKey(e: any) {
        this.onEnterKeyEvent.emit(e);
    }

    actionOnCopyButtonClick() {
        if (!this.control.value) {
            return;
        }
        // console.log('actionOnCopyButtonClick', this.control.value);

        this.copyButton = false;

        AppHelper.copyToClipboard(this.control.value);

        this._message
            .simpleMessage('Conteúdo copiado.', 500)
            .pipe(take(1))
            .subscribe(() => {
                this.copyButton = true;
            });
    }

    togglePopover() {
        this.popoverVisible = !this.popoverVisible;
    }

    get showCopyButton(): boolean {
        if (!this.control || !this.control.value) {
            return false;
        }

        if (this.control.disabled) {
            return true;
        }

        return false;
    }
}
