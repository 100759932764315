import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AppHelper } from '../../../../app.helper';
@Component({
    selector: 'otk-cmp-numberbox-search',
    templateUrl: './cmp-numberbox-search.component.html',
    styleUrls: ['./cmp-numberbox-search.component.scss'],
})
export class CmpNumberboxSearchComponent implements OnInit {
    @Input() initialValue: any = null;
    @Input() inputPlaceholder: string = 'Pesquisar...';
    @Output() searchEvent = new EventEmitter<string>();
    @Output() isSearchValidEvent = new EventEmitter<boolean>();

    searchButton: any;
    control: FormControl = new FormControl();

    constructor() {
        this.searchButton = {
            icon: 'search',
        };
    }

    ngOnInit() {
        this.isSearchValidEvent.emit(false);
    }

    ngOnDestroy() {}

    ngOnChanges() {
        if (this.initialValue) {
            AppHelper.setCtrlValue(this.control, this.initialValue);
            setTimeout(() => {
                this.isSearchValidEvent.emit(true);
                this.searchEvent.emit(this.control.value);
            }, 300);
        } else {
            AppHelper.setCtrlValue(this.control, null);
        }
    }

    actionValueChanged(e: any) {
        if (!e) return;

        this.isSearchValidEvent.emit(e.value ? true : false);
        this.searchEvent.emit(e.value);
    }
}
