import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { AuthService } from '../../auth/services/auth.service';

@Component({
    selector: 'app-contratacao-conclusao-dialog',
    templateUrl: './contratacao-conclusao-dialog.component.html',
    styleUrls: ['./contratacao-conclusao-dialog.component.scss'],
})
export class ContratacaoConclusaoDialogComponent implements OnInit {
    public title: string;
    public message: string;

    constructor(
        public dialogRef: MatDialogRef<ContratacaoConclusaoDialogComponent>,
        private _authService: AuthService
    ) {}

    ngOnInit() {}

    atualizarInformacoesLicencas(): void {
        this._authService.recuperarLogin(true);
        this.dialogRef.close(true);
    }
}
