import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material';
import { DxPopoverModule, DxTemplateModule } from 'devextreme-angular';
import { TextareaModule } from '../textarea/textarea.module';
import { CmpTextareaLabelComponent } from './cmp-textarea-label.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TextareaModule,
        DxPopoverModule,
        DxTemplateModule,
        MatTooltipModule,
    ],
    declarations: [CmpTextareaLabelComponent],
    exports: [CmpTextareaLabelComponent],
})
export class TextareaLabelModule {}
