import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatTooltipModule,
} from '@angular/material';

import { KernelModule } from '../../../kernel/kernel.module';
import { InputNumberEditModule } from './input-number-edit/input-number-edit.module';
import { MatInputBasicSearchComponent } from './mat-input-basic-search/mat-input-basic-search.component';
import { MatInputSpanComponent } from './mat-input-span/mat-input-span.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule.withConfig({
            warnOnNgModelWithFormControl: 'never',
        }),
        FlexLayoutModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatTooltipModule,

        KernelModule,
    ],
    exports: [
        InputNumberEditModule,

        MatInputBasicSearchComponent,
        MatInputSpanComponent,
    ],
    declarations: [MatInputBasicSearchComponent, MatInputSpanComponent],
})
export class InputsComponentsModule {}
