import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import {
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatIconModule,
    MatTooltipModule,
} from '@angular/material';
import { ButtonsComponentsModule } from '../buttons/buttons-components.module';

import { CmpButtonsSaveCancelDialogComponent } from './buttons-save-cancel-dialog/cmp-buttons-save-cancel-dialog.component';
import { CmpButtonsSaveRemoveDialogComponent } from './buttons-save-remove-dialog/cmp-buttons-save-remove-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        FlexLayoutModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDialogModule,
        MatIconModule,
        MatTooltipModule,
        ButtonsComponentsModule,
    ],
    exports: [
        CmpButtonsSaveRemoveDialogComponent,
        CmpButtonsSaveCancelDialogComponent,
    ],
    declarations: [
        CmpButtonsSaveRemoveDialogComponent,
        CmpButtonsSaveCancelDialogComponent,
    ],
})
export class DialogsComponentsModule {}
